import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useAddingPlaceContext } from "context/addingPlace";
import axios from "axios";
import { BACKEND_URL } from "backendUrl";
import { Menu, NewMenu } from "data/types";

export interface PageAddListing4Props {}

const PageAddListing4: FC<PageAddListing4Props> = () => {
  const { menus, setMenus, placeId, menuImages, setMenuImages } = useAddingPlaceContext();
  const [menuName, setMenuName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [starters, setStarters] = useState<string>("");
  const [mainCourse, setMainCourse] = useState<string>("");
  const [drinks, setDrinks] = useState<string>("");
  const [desserts, setDesserts] = useState<string>("");
  const [description, setDescription] = useState<string>(""); // New state for description
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedImages(filesArray);
    }
  };

  const handleAddMenu = async () => {
    if (!menuName || !price) {
      setError("Please fill in all required fields (name and price).");
      return;
    }

    // Upload images
    const uploadImagePromises = selectedImages.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      return axios.post(`${BACKEND_URL}upload-image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data.imageUrl);
    });

    setIsLoading(true);
    try {
      const uploadedImageUrls = await Promise.all(uploadImagePromises);
      setMenuImages([...menuImages, ...uploadedImageUrls]); // Add uploaded images to menu images

      const newMenu: NewMenu = {
        place: placeId,
        name: menuName,
        price,
        starters: starters.split(",").map((item) => item.trim()), // Convert comma-separated string to array
        mainCourse: mainCourse.split(",").map((item) => item.trim()), // Convert comma-separated string to array
        drinks: drinks.split(",").map((item) => item.trim()), // Convert comma-separated string to array
        desserts: desserts.split(",").map((item) => item.trim()), // Convert comma-separated string to array
        menu_images: uploadedImageUrls, // Include uploaded images
        menu_description: description, // Include description
      };

      setMenus([...menus, newMenu as unknown as Menu]);
      setMenuName("");
      setPrice(0);
      setStarters("");
      setMainCourse("");
      setDrinks("");
      setDesserts("");
      setDescription(""); // Clear description field
      setSelectedImages([]);
      setError(null);
    } catch (error) {
      console.error("Error adding menu:", error);
      setError("Error adding menu. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommonLayout index="03" backtHref="/add-listing-2" nextHref="/add-listing-3">
      <>
        <h2 className="text-2xl font-semibold">Add Menus</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <FormItem label="Menu name">
            <Input
              placeholder="Menu name"
              value={menuName}
              onChange={(e) => setMenuName(e.target.value)}
            />
          </FormItem>

          <FormItem label="Price">
            <Input
              type="number"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
            />
          </FormItem>

          <FormItem label="Description">
            <Input
              placeholder="Menu description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormItem>

          <FormItem label="Starters">
            <Input
              placeholder="Comma-separated list of starters"
              value={starters}
              onChange={(e) => setStarters(e.target.value)}
            />
          </FormItem>

          <FormItem label="Main Course">
            <Input
              placeholder="Comma-separated list of main courses"
              value={mainCourse}
              onChange={(e) => setMainCourse(e.target.value)}
            />
          </FormItem>

          <FormItem label="Drinks">
            <Input
              placeholder="Comma-separated list of drinks"
              value={drinks}
              onChange={(e) => setDrinks(e.target.value)}
            />
          </FormItem>

          <FormItem label="Desserts">
            <Input
              placeholder="Comma-separated list of desserts"
              value={desserts}
              onChange={(e) => setDesserts(e.target.value)}
            />
          </FormItem>

          {/* Image Upload Section */}
          <FormItem label="Images">
            <input type="file" multiple onChange={handleImageChange} />
            <div className="grid grid-cols-2 gap-4 mt-4">
              {selectedImages.map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  alt={`Selected ${index + 1}`}
                  className="object-cover w-full h-32 rounded-md"
                />
              ))}
            </div>
          </FormItem>

          {error && <p className="text-red-500">{error}</p>}

          <ButtonSecondary onClick={handleAddMenu} disabled={isLoading}>
            {isLoading ? "Adding..." : "Add Menu"}
          </ButtonSecondary>

          <div>
            <h3 className="text-xl font-semibold">Added Menus</h3>
            <ul>
              {menus.map((menu, index) => (
                <li key={index} className="mb-4">
                  <p>Price: PKR {menu.price}</p>
                  <h4 className="font-medium">{menu.name}</h4>
                  <p>Description: {menu.menu_description}</p> {/* Display description */}
                  <p>Starters: {menu.starters.join(", ")}</p>
                  <p>Main Course: {menu.mainCourse.join(", ")}</p>
                  <p>Drinks: {menu.drinks.join(", ")}</p>
                  <p>Desserts: {menu.desserts.join(", ")}</p>
                  <p>Images:</p>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {menu.menu_images.map((image, i) => (
                      <img
                        key={i}
                        src={image}
                        alt={`Menu ${index + 1} Image ${i + 1}`}
                        className="object-cover w-full h-32 rounded-md"
                      />
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
