import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import CustomDatePicker from "./CustomDatePicker";
import { useSearchContext } from "context/search";
import { BACKEND_URL } from "backendUrl";
import { GoogleCalendarEvent, VendorResponse } from "data/types";

const GoogleCalendarDateRange = forwardRef((props, ref) => {
    const { selectedVenue, selectedDate, setSelectedDate, highlightDates, setHighlightDates, selectedHall, selectedMenu } = useSearchContext();
    const [tokenRefreshed, setTokenRefreshed] = useState(false);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const vendorEmail = selectedVenue?.vendor_email;
                if (!vendorEmail) {
                    console.error('Vendor email not found in selectedVenue');
                    return;
                }

                const response = await axios.get<VendorResponse>(`${BACKEND_URL}vendor-by-email`, {
                    params: { email: vendorEmail }
                });

                const accessToken = response.data.accessToken;

                const fetchCalendarEvents = async () => {
                    const eventsResponse = await axios.get<{ items: GoogleCalendarEvent[] }>(
                        `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
                        {
                            params: {
                                singleEvents: true,
                            },
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );

                    const events = eventsResponse.data.items;
                    console.log("Events fetched:", events);
                    const newHighlightDates: { date: Date, type: 'day' | 'night' | 'fullyBooked' }[] = [];

                    // Extract hall or menu name based on venue type
                    const selectedName = selectedVenue?.type === "Caterers" 
                        ? selectedMenu?.name.toLowerCase() 
                        : selectedHall?.name.toLowerCase();

                    console.log("Selected Name:", selectedName);

                    events.forEach(event => {
                        const eventStartStr = event.start.dateTime || event.start.date;
                        const eventEndStr = event.end.dateTime || event.end.date;
                        const eventDescription = event.description ? event.description.toLowerCase() : '';

                        // Check if event description matches the selected name (hall or menu)
                        const nameMatched = selectedName && eventDescription.includes(selectedName);

                        if (eventStartStr && eventEndStr && nameMatched) {
                            const eventStart = new Date(eventStartStr);
                            const eventEnd = new Date(eventEndStr);

                            let type: 'day' | 'night' | 'fullyBooked' = 'day';

                            if (eventStart.getHours() === 1 && eventEnd.getHours() === 11) {
                                type = 'day';
                            } else if (eventStart.getHours() === 13 && eventEnd.getHours() === 23) {
                                type = 'night';
                            }

                            const existingHighlight = newHighlightDates.find(highlightDate =>
                                highlightDate.date.toDateString() === eventStart.toDateString()
                            );

                            if (existingHighlight) {
                                existingHighlight.type = 'fullyBooked';
                            } else {
                                newHighlightDates.push({
                                    date: eventStart,
                                    type
                                });
                            }
                        }
                    });

                    console.log("New Highlight Dates:", newHighlightDates);
                    setHighlightDates(newHighlightDates);
                };

                await fetchCalendarEvents();
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, [selectedVenue, setHighlightDates]);

    useImperativeHandle(ref, () => ({
        handleReserve: () => {
            setSelectedDate(selectedDate);
        }
    }));

    return (
        <div className="listingSection__wrap overflow-hidden">
            <div>
                <h2 className="text-2xl font-semibold">Availability</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    Prices may increase on weekends or holidays
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="mt-4">
                <div className="flex items-center mt-2">
                    <div className="w-4 h-4 bg-red-500 shadow-md rounded-full mr-2"></div>
                    <span className="text-neutral-500 dark:text-neutral-400">Fully Booked</span>
                </div>
                <div className="flex items-center mt-2">
                    <div className="w-4 h-4 bg-white dark:bg-neutral-900 rounded-full shadow-md border border-neutral-400 mr-2"></div>
                    <span className="text-neutral-500 dark:text-neutral-400">Available</span>
                </div>
                <div className="flex items-center mt-2">
                    <div className="w-4 h-4 bg-yellow-500 shadow-md rounded-full mr-2"></div>
                    <span className="text-neutral-500 dark:text-neutral-400">Day Event Booked</span>
                </div>
                <div className="flex items-center mt-2">
                    <div className="w-4 h-4 bg-blue-900 shadow-md rounded-full mr-2"></div>
                    <span className="text-neutral-500 dark:text-neutral-400">Night Event Booked</span>
                </div>
            </div>

            <div className="">
                <CustomDatePicker
                    highlightDates={highlightDates}
                    selectedDate={selectedDate}
                    onChange={setSelectedDate}
                />
            </div>
        </div>
    );
});

export default GoogleCalendarDateRange;
