import React, { useEffect, useState } from "react";
import SectionHero from "components/SectionHero/SectionHero";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { useFirebase } from "context/firebase";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { getAuth } from "firebase/auth";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";

function PageHome() {
  const firebase = useFirebase();
  const [isVerificationPopupVisible, setIsVerificationPopupVisible] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const verified = await firebase.checkEmailVerification(currentUser);
        setIsVerificationPopupVisible(!verified);
      } else {
        setIsVerificationPopupVisible(false);
      }
    });

    return () => unsubscribe();
  }, [firebase]);

  const handleResendVerification = async () => {
    try {
      const user = firebase.getCurrentUser();
      if (user) {
        await firebase.sendVerificationEmail(user);
        console.log('Verification email sent');
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error sending verification email:', error.message);
      } else {
        console.error('Unexpected error sending verification email:', error);
      }
    }
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        <SectionSliderNewCategories uniqueClassName="PageHome_s1" />

        <SectionOurFeatures />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

        <SectionHowItWork />

        <div className="relative py-16">
          <BackgroundSection className="bg-teal-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>

        <SectionGridCategoryBox />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionSubscribe2 />
        </div>
      </div>

      {isVerificationPopupVisible && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl mb-4">Verify Your Email</h2>
            <p className="mb-4">Please verify your email address to continue using the app.</p>
            <ButtonPrimary onClick={handleResendVerification}>
              Resend Verification Email
            </ButtonPrimary>
          </div>
        </div>
      )}
    </div>
  );
}

export default PageHome;
