import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchContext } from "context/search";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { useFirebase } from "context/firebase";
import { Hall, Place, GoogleCalendarEvent, SavedPlace } from 'data/types';
import { BACKEND_URL } from "backendUrl";
import axios from "axios";
import { toast } from "react-toastify";

interface StayCardProps {
  className?: string;
  data: Place | Hall;
  size?: "default" | "small";
  events?: GoogleCalendarEvent[];
  selectedDate?: Date | null;
  isHomePage?: boolean;
  cardType?: string;
  isPerPerson?: boolean;
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  events = [],
  selectedDate = null,
  isHomePage = false,
  cardType,
  isPerPerson = false,
}) => {
  const navigate = useNavigate();
  const { setSelectedHall, setSelectedVenue, selectedVenue, savePlace, removePlace } = useSearchContext();
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState<string[]>([]);
  const { getCurrentUser } = useFirebase();
  const [isSaved, setIsSaved] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupColorClass, setPopupColorClass] = useState("bg-teal-800");

  const showPopup = (message: string, colorClass = "bg-teal-800") => {
    setPopupMessage(message);
    setPopupColorClass(colorClass);
    setIsPopupVisible(true);
    setTimeout(() => {
      setIsPopupVisible(false);
    }, 3000);
  };

  const handleSavePlace = async (placeId: string) => {
    const user = getCurrentUser();
    if (user) {
      await savePlace(placeId, user.uid);
      setIsSaved(true);
      showPopup("Added to wishlist!");
    } else {
      showPopup("Please log in first", "bg-red-600");
    }
  };

  const handleRemovePlace = async (placeId: string) => {
    const user = getCurrentUser();
    if (user) {
      await removePlace(placeId, user.uid);
      setIsSaved(false);
      showPopup("Removed from wishlist!");
    } else {
      showPopup("Please log in first", "bg-red-600");
    }
  };

  const isPlace = (data: Place | Hall): data is Place => {
    return (data as Place).rating !== undefined;
  };

  const handleCardClick = () => {
    if (isPlace(data)) {
      setSelectedVenue(data);
    } else {
      setSelectedHall(data);
    }
    const encodedName = encodeURIComponent(data.name); // Encode the name to handle slashes
    const encodedVenueName = selectedVenue ? encodeURIComponent(selectedVenue.name) : '';
    navigate(
      isPlace(data)
        ? `/listing-vendor/${encodedName}/${data.type}/${data.id}`
        : `/listing-vendor-detail/${selectedVenue?.id}/${selectedVenue?.type}/option/${encodedName}/${data._id}`
    );
  };

  useEffect(() => {
    const loadImages = async () => {
      const imageUrls = isPlace(data)
        ? data.google_images?.length
          ? data.google_images
          : data.images
        : data.images;
      setImages(imageUrls);
      setLoading(false);
    };
    loadImages();
  }, [data]);

  useEffect(() => {
    const checkIfSaved = async () => {
      if (isPlace(data)) {
        const user = getCurrentUser();
        if (user) {
          const response = await axios.get(`${BACKEND_URL}saved-places/${user.uid}`);
          const savedPlaceIds = response.data.map((savedPlace: SavedPlace) => savedPlace.place_id);
          setIsSaved(savedPlaceIds.includes(data.id));
        }
      }
    };
    checkIfSaved();
  }, [data, getCurrentUser]);

  const getLocationAfterFirstComma = (address: string) => {
    const parts = address.split(",");
    return parts.length > 1 ? parts.slice(1).join(",").trim() : address;
  };

  const shortenAddress = (address: string, maxLength = 60) => {
    if (address.length <= maxLength) return address;
    return address.slice(0, maxLength) + '...';
  };

  const renderSliderGallery = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-full">
          <p></p>
        </div>
      );
    }

    return (
      <div className="relative w-full" onClick={(e) => e.stopPropagation()}>
        {images && images.length > 0 ? (
          <GallerySlider
            uniqueID={`StayCard_${isPlace(data) ? data.id : data._id}`}
            ratioClass="aspect-w-4 aspect-h-3"
            galleryImgs={images}
          />
        ) : (
          <div className="flex items-center justify-center h-full">
            <p>No images available</p>
          </div>
        )}
        {isPlace(data) && (
          <div onClick={() => isSaved ? handleRemovePlace(data.id) : handleSavePlace(data.id)}>
            <BtnLikeIcon className="absolute right-3 top-3 z-[1]" isLiked={isSaved} />
          </div>
        )}
      </div>
    );
  };

  const isHallBooked = () => {
    if (!selectedDate) {
      console.log("No selected date.");
      return false;
    }

    const formattedSelectedDate = selectedDate.toISOString().split("T")[0];
    console.log("Checking bookings for:", data.name, "on", formattedSelectedDate);

    const bookingsForHall = events.filter(event => {
      const eventDateStr = event.start.dateTime || event.start.date;
      if (!eventDateStr) {
        console.log("Event has no start date:", event.start, event.end);
        return false;
      }

      const eventDate = new Date(eventDateStr).toISOString().split("T")[0];
      const isBooked = event.summary.includes(data.name) && eventDate === formattedSelectedDate;
      return isBooked;
    });

    const isFullyBooked = bookingsForHall.length === 2;
    console.log("Bookings for hall on", formattedSelectedDate, ":", bookingsForHall.length, "Is fully booked:", isFullyBooked);
    return isFullyBooked;
  };

  const renderContent = () => {
    const linkTo = isPlace(data) ? "/listing-stay" : "/listing-stay-detail";

    let priceRange = '';
    if (isPlace(data)) {
      if (data.menu_based && data.menus.length > 0) {
        const lowestPrice = Math.min(...data.menus.map(menu => menu.price));
        const highestPrice = Math.max(...data.menus.map(menu => menu.price));
        priceRange = `PKR ${lowestPrice.toLocaleString()} - ${highestPrice.toLocaleString()}`;
      } else if (data.halls.length > 0) {
        // Check if any hall has a base price and use it as the range if available
        const hallsWithBasePrice = data.halls.filter(hall => hall.basePrice);
        if (hallsWithBasePrice.length > 0) {
          const lowestBasePrice = Math.min(...hallsWithBasePrice.map(hall => hall.basePrice));
          const highestBasePrice = Math.max(...hallsWithBasePrice.map(hall => hall.basePrice));
          priceRange = `PKR ${lowestBasePrice.toLocaleString()} - ${highestBasePrice.toLocaleString()}`;
        } else {
          const lowestPrice = Math.min(...data.halls.map(hall => hall.price));
          const highestPrice = Math.max(...data.halls.map(hall => hall.price));
          priceRange = `PKR ${lowestPrice.toLocaleString()} - ${highestPrice.toLocaleString()}`;
        }
      }
    }

    const isHall = !isPlace(data);

    return (
      <div onClick={handleCardClick}>
        <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
          <div className="space-y-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {isPlace(data) &&
                (data.type === "Caterers"
                  ? `${data.menus.length} ${data.menus.length > 1 ? "Menus" : "Menu"}`
                  : `${data.halls.length} ${data.halls.length > 1 ? "Options" : "Option"}`)}
            </span>

            <div className="flex items-center space-x-2">
              <h2
                className={`font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                  }`}
              >
                <span className="line-clamp-1">{data.name}</span>
              </h2>
            </div>
            {isPlace(data) ? (
              <div
                className="flex items-start text-neutral-500 dark:text-neutral-400 text-sm space-x-2 line-clamp-1"
                style={{ minHeight: '40px' }} // Adjust this height based on your design
              >
                {shortenAddress(data.address)}
              </div>
            ) : (
              cardType === "Venues" && data.seating_capacity > 0 && (
                <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                  <i className="las la-user text-lg"></i>
                  <span className="">{data.seating_capacity.toLocaleString()} guests</span>
                </div>
              )
            )}
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
              {isHomePage
                ? priceRange
                : isPlace(data)
                  ? (
                    `PKR ${data.halls[0].price.toLocaleString()}`
                  )
                  : !selectedVenue?.menu_based && (
                    data.hall_isperperson
                      ? (
                        <>
                          PKR {data.price.toLocaleString()}
                          <span className="text-xs text-neutral-500 font-medium"> /per person</span>
                        </>
                      )
                      : (selectedVenue?.type === "Decorators" && data.hasRange)
                        ? `PKR ${data.priceRange}`
                        : data.hasBasePrice // Render the base price if available
                          ? `Starting From PKR ${data.basePrice.toLocaleString()}`
                          : `PKR ${data.price.toLocaleString()}`
                  )
              }
            </span>

            <div className="flex items-center space-x-2 text-sm text-neutral-500 dark:text-neutral-400">
              {isPlace(data) && (
                <>
                  <StartRating reviewCount={data.userRatingCount} point={data.rating} />
                </>
              )}
            </div>
          </div>
          {isHall && isHallBooked() && (
            <div className="text-red-500 font-semibold mt-2">
              Not available for your date
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform lg:hover:shadow-xl md:hover:shadow-xl shadow-none transition-shadow cursor-pointer ${className}`}
      data-nc-id="StayCard"
    >

      {renderSliderGallery()}
      {renderContent()}

      <div className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 p-2 ${popupColorClass} lg:w-3/4 w-1/2 text-center text-white rounded transition-opacity duration-200 ease-in-out ${isPopupVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        {popupMessage}
      </div>
    </div>
  );
};

export default StayCard;
