import React, { FC, useEffect } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./StaySearchForm.css";

const StaySearchForm: FC<{}> = () => {
  const navigate = useNavigate();
  const { location, guests, dates, searchPlaces } = useSearchContext();

  useEffect(() => {
    console.log("Location changed:", location);
  }, [location]);

  useEffect(() => {
    console.log("date changed:", dates);
  }, [dates]);

  useEffect(() => {
    console.log("number of guests changed:", guests);
  }, [guests]);

  const handleSubmit = async () => {
    console.log("submit");
    if (!location || !dates.startDate || !dates.endDate || !guests) {
      toast.error("Please fill all fields");
      return;
    }

    try {
      console.log("search function being called from stay component");
      await searchPlaces();
      navigate("listing-stay-map");
    } catch (error) {
      console.error("Error searching for places:", error);
      toast.error("Error searching for places");
    }
  };

  return (
    <div className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      <LocationInput className="flex-[1.5]" />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <StayDatesRangeInput className="flex-1" />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <GuestsInput className="flex-1" />
    </div>
  );
};

export default StaySearchForm;
