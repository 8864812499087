import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { useSearchContext } from "context/search";

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText = "Location?",
}) => {
  const { location, setLocation } = useSearchContext();
  const [value, setValue] = useState(defaultValue);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const suggestions = [
    "Islamabad, Pakistan",
    "Lahore, Pakistan",
    "Karachi, Pakistan",
    "Peshawar, Pakistan",
  ];
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(location);
    setFilteredSuggestions(suggestions);
  }, [location]);

  const handleSelectLocation = (address: string) => {
    setLocation(address);
    setValue(address);
    setShowSuggestions(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    setValue(inputValue);
    setShowSuggestions(true);
    setFilteredSuggestions(
      suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  };

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: string[];
  }) => {
    if (items.length === 0) {
      return (
        <div className="py-2 text-sm text-neutral-500 dark:text-neutral-400">
          No suggestions found
        </div>
      );
    }

    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => (
            <div
              className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
              onClick={() => handleSelectLocation(item)}
              key={item}
            >
              <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
              <span className="">{item}</span>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Select Location"}
            value={value}
            onChange={handleInputChange}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {showSuggestions ? (
            renderSearchValues({
              heading: "Locations",
              items: filteredSuggestions,
            })
          ) : (
            renderSearchValues({
              heading: "Popular Locations",
              items: suggestions,
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
