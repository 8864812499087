import React, { FC, useRef, useState, useEffect } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import GooglePlacesAutocomplete from "components/GooglePlacesAutocomplete";
import { useAddingPlaceContext } from "context/addingPlace";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "backendUrl";
import Modal from "./Modal";

export interface PageAddListing1Props {}

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const {
    placeName,
    setPlaceName,
    vendorEmail,
    setVendorEmail,
    vendorContactNumber,
    setVendorContactNumber,
    placeId,
    setPlaceId,
    featured,
    setFeatured,
    type,
    setType,
    cancellationPolicy,
    setCancellationPolicy,
    discountPercentage,
    setDiscountPercentage,
    taxAndFeesPercentage,
    setTaxAndFeesPercentage,
    fixedPrice,
    setFixedPrice,
    isPerPerson,
    setIsPerPerson,
    menuBased,
    setMenuBased,
    amenities,
    setAmenities,
    placeType,
    setPlaceType,
    placeNameWithoutGoogle,
    setPlaceNameWithoutGoogle,
    setAddress, // Add this line to handle the address
  } = useAddingPlaceContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const [suggestions, setSuggestions] = useState<{ description: string; place_id: string }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [newAmenityName, setNewAmenityName] = useState<string>("");
  const [newAmenityIconFile, setNewAmenityIconFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSelectPlace = (address: string, id: string) => {
    if (placeType === "googlePlace") {
      setPlaceName(address);
      setPlaceId(id);
    } else {
      setAddress(address);
    }
  };

  const handleContinue = () => {
    setIsModalOpen(true);
  };

  const handleModalSubmit = () => {
    if (newAmenityName || newAmenityIconFile) {
      setError("Please add the amenity first");
      return;
    }
    setIsModalOpen(false);
    navigate("/add-listing-2");
  };

  const handleAddAmenity = async () => {
    if (newAmenityName && newAmenityIconFile) {
      const formData = new FormData();
      formData.append("file", newAmenityIconFile);

      try {
        const response = await axios.post(`${BACKEND_URL}upload-image`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        const newAmenityIcon = response.data.imageUrl;

        setAmenities([...amenities, { name: newAmenityName, icon: newAmenityIcon }]);
        setNewAmenityName("");
        setNewAmenityIconFile(null);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
        setError(null);
      } catch (error) {
        console.error("Error uploading amenity icon:", error);
      }
    }
  };

  const handleFixedPriceChange = (checked: boolean) => {
    setFixedPrice(checked);
  };

  const handleIsPerPersonChange = (checked: boolean) => {
    setIsPerPerson(checked);
  };

  const handleMenuBasedChange = (checked: boolean) => {
    setMenuBased(checked);
  };

  return (
    <div>
      <CommonLayout index="01" backtHref="/add-listing-1" nextHref="#" onNextClick={handleContinue}>
        <>
          <h2 className="text-2xl font-semibold">Add Listing</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="space-y-8">
            {/* Place Type */}
            <FormItem
              label="Place Type"
              desc="Choose whether this is a Google Place or a Normal Place"
            >
              <div className="flex space-x-4">
                <label>
                  <input
                    type="radio"
                    name="placeType"
                    value="googlePlace"
                    checked={placeType === "googlePlace"}
                    onChange={() => setPlaceType("googlePlace")}
                  />
                  Google Place
                </label>
                <label>
                  <input
                    type="radio"
                    name="placeType"
                    value="normalPlace"
                    checked={placeType === "normalPlace"}
                    onChange={() => setPlaceType("normalPlace")}
                  />
                  Normal Place
                </label>
              </div>
            </FormItem>

            {/* Manual Place Name */}
            <FormItem
              label="Manual Place Name"
              desc="Enter the place name manually"
            >
              <Input
                placeholder="Place name"
                value={placeNameWithoutGoogle}
                onChange={(e) => setPlaceNameWithoutGoogle(e.target.value)}
              />
            </FormItem>

            {/* Place Name (Google Places) */}
            <FormItem
              label="Place name"
              desc="Enter the place name using Google Places Autocomplete"
            >
              <div className="relative">
                <Input
                  ref={inputRef}
                  placeholder="Place name"
                  value={placeName}
                  onChange={(e) => setPlaceName(e.target.value)}
                />
                <GooglePlacesAutocomplete
                  inputRef={inputRef}
                  onSelect={(address, id) => handleSelectPlace(address, id)}
                  setSuggestions={setSuggestions}
                />
              </div>
            </FormItem>

            {/* Vendor Email */}
            <FormItem
              label="Vendor email"
              desc="Enter the vendor email address"
            >
              <Input
                type="email"
                placeholder="Vendor email"
                value={vendorEmail}
                onChange={(e) => setVendorEmail(e.target.value)}
              />
            </FormItem>

            {/* Vendor Contact Number */}
            <FormItem
              label="Vendor Contact Number"
              desc="Enter the vendor contact number"
            >
              <Input
                type="tel"
                placeholder="Vendor contact number"
                value={vendorContactNumber}
                onChange={(e) => setVendorContactNumber(e.target.value)}
              />
            </FormItem>

            {/* Featured */}
            <FormItem label="Featured" desc="Mark this place as featured">
              <input
                type="checkbox"
                checked={featured}
                onChange={(e) => setFeatured(e.target.checked)}
              />
            </FormItem>
          </div>
        </>
      </CommonLayout>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
      >
        <div className="p-6 md:grid md:grid-cols-2 md:gap-8">
          <div className="space-y-4">
            {/* Type */}
            <FormItem label="Type" desc="Select the type of the place">
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="w-full p-2 border border-neutral-300 rounded-md"
              >
                <option value="" disabled>
                  Select type
                </option>
                <option value="Venues">Venues</option>
                <option value="Decorators">Decorators</option>
                <option value="Caterers">Caterers</option>
                <option value="Photographers">Photographers</option>
              </select>
            </FormItem>

            {/* Cancellation Policy */}
            <FormItem
              label="Cancellation Policy"
              desc="Enter the cancellation policy"
            >
              <Input
                placeholder="Cancellation Policy"
                value={cancellationPolicy}
                onChange={(e) => setCancellationPolicy(e.target.value)}
              />
            </FormItem>

            {/* Fixed Price, Per Person Pricing, Menu Based */}
            <div className="flex space-x-4">
              <FormItem label="Menu Based" desc="Is the pricing menu-based?">
                <input
                  type="checkbox"
                  checked={menuBased}
                  onChange={(e) => handleMenuBasedChange(e.target.checked)}
                />
              </FormItem>
            </div>
          </div>
          <div className="space-y-4">
            {/* Discount Percentage */}
            <FormItem
              label="Discount Percentage"
              desc="Enter the discount percentage"
            >
              <Input
                type="number"
                placeholder="Discount Percentage"
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(Number(e.target.value))}
              />
            </FormItem>

            {/* Tax and Fees Percentage */}
            <FormItem
              label="Tax and Fees Percentage"
              desc="Enter the tax and fees percentage"
            >
              <Input
                type="number"
                placeholder="Tax and Fees Percentage"
                value={taxAndFeesPercentage}
                onChange={(e) => setTaxAndFeesPercentage(Number(e.target.value))}
              />
            </FormItem>

            {/* Amenities */}
            <FormItem label="Amenities" desc="Add amenities with name and icon">
              <div className="space-y-4">
                <div className="flex space-x-4 items-center">
                  <Input
                    placeholder="Amenity Name"
                    value={newAmenityName}
                    onChange={(e) => setNewAmenityName(e.target.value)}
                    className="flex-grow"
                  />
                  <input
                    type="file"
                    ref={inputRef}
                    onChange={(e) => {
                      if (e.target.files) {
                        setNewAmenityIconFile(e.target.files[0]);
                      }
                    }}
                    className="p-2 border border-neutral-200 rounded"
                  />
                  <button
                    onClick={handleAddAmenity}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Add
                  </button>
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <ul className="space-y-2">
                  {amenities.map((amenity, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <img
                        src={amenity.icon}
                        alt={amenity.name}
                        width="20"
                        height="20"
                      />
                      <span>{amenity.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </FormItem>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PageAddListing1;
