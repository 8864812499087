import axios from 'axios';
import { BACKEND_URL } from 'backendUrl';

interface AccessTokenResponse {
  ACCESS_TOKEN: string;
}

const getAccessToken = async (merchantId: string, securedKey: string, basketID: string, amount: number): Promise <string> => {
  try {
    // Construct the URL with query parameters
    const url = `${BACKEND_URL}get-token?merchantId=${encodeURIComponent(merchantId)}&securedKey=${encodeURIComponent(securedKey)}&basketID=${encodeURIComponent(basketID)}&amount=${encodeURIComponent(amount)}`;

    // Make a GET request to the API to get the access token
    const response = await axios.get<AccessTokenResponse>(url);
    
    return JSON.stringify(response.data); // Return the access token
  } catch (error: any) {
    console.error('Error getting access token:', error.response?.data || error.message);
    throw new Error('Failed to get access token');
  }
};

export default getAccessToken;
