import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
  color: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "WhatsApp",
    subHeading:
      "Get instant booking confirmations via WhatsApp, allowing you to quickly verify and keep track of your event arrangements on the go.",
    color: "#25D366", // Green
  },
  {
    id: "2",
    heading: "Gmail",
    subHeading: "Receive and manage your booking confirmations directly through Gmail, ensuring all event details are conveniently organized in your inbox.",
    color: "#D93025", // Red
  },
  {
    id: "3",
    heading: "Paymob",
    subHeading:
      "Ensure secure and convenient transactions with Paymob, offering multiple payment options and reliable processing to simplify the payment process for your events.",
    color: "#4285F4", // Blue
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc="Discover the key features and services we offer, ensuring a seamless and efficient experience for all your needs."
      >
        🚀 Key Services
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800 flex items-start"
          >
            <div>
              <h3
                className="text-2xl font-semibold leading-none md:text-3xl"
                style={{ color: item.color }}
              >
                {item.heading}
              </h3>
              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                {item.subHeading}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
