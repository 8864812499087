import React, { FC, useEffect, useMemo, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { Place, TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import NextPrev from "shared/NextPrev/NextPrev";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import useNcId from "hooks/useNcId";
import CardCategory3Skeleton from "components/CardCategory3/CardCategory3Skeleton";
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery for screen size detection

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Featured Vendors",
  subHeading = "Showcasing Our Top-Rated And Most Trusted Vendors",
  className = "",
  itemClassName = "",
  categoryCardType = "card3",
  itemPerRow = 5,
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const { featuredVenues, getFeaturedVenues, setSelectedVenue } = useSearchContext();
  const navigate = useNavigate();
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + uniqueClassName + useNcId();
  const [venuesLoaded, setVenuesLoaded] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  // Use media queries to determine screen size
  const isSmallScreen = useMediaQuery({ maxWidth: 640 });

  const MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: { perView: itemPerRow - 1 },
        1024: { gap: 20, perView: itemPerRow - 1 },
        768: { gap: 20, perView: itemPerRow - 2 },
        640: { gap: 20, perView: itemPerRow - 3 },
        500: { gap: 20, perView: 1.3 },
      },
    });
  }, [UNIQUE_CLASS, itemPerRow]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getFeaturedVenues();
        setVenuesLoaded(true);
        setTimeout(() => setIsDataReady(true));
      } catch (error) {
        console.error("Failed to fetch featured venues:", error);
      }
    };

    fetchData();
  }, [getFeaturedVenues]);

  useEffect(() => {
    if (venuesLoaded && isDataReady) {
      const timeoutId = setTimeout(() => {
        MY_GLIDEJS.mount();
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [MY_GLIDEJS, venuesLoaded, isDataReady]);

  const handleCardClick = (venue: Place) => {
    setSelectedVenue(venue);
    navigate(`/listing-vendor/${venue.name}/${venue.type}/${venue.id}`);
  };

  const sortedVenues = useMemo(() => {
    return featuredVenues.sort((a, b) => {
      if (a.name === "RMK Farmhouse") return -1;
      if (b.name === "RMK Farmhouse") return 1;
      if (a.name === "Raffay Productions") return -1;
      if (b.name === "Raffay Productions") return 1;
      if (a.name === "The Millennium - Marquees & Events") return -1;
      if (b.name === "The Millennium - Marquees & Events") return 1;
      return 0;
    });
  }, [featuredVenues]);

  const renderSkeletons = () => {
    // Render 2 skeletons for small screens, 5 for larger screens
    const skeletonCount = isSmallScreen ? 1 : 5;
    return Array.from({ length: skeletonCount }).map((_, index) => (
      <li key={index} className={`glide__slide ${itemClassName}`}>
        <CardCategory3Skeleton />
      </li>
    ));
  };

  const renderCard = (item: Place, index: number) => {
    const taxonomyItem: TaxonomyType = {
      id: item.id,
      href: `/`,
      name: item.name,
      taxonomy: "category",
      count: item.rating,
      halls: item.halls.length,
      thumbnail: item.images[0] || item.google_images[0],
    };

    const cardComponent = (() => {
      switch (categoryCardType) {
        case "card3":
          return <CardCategory3 taxonomy={taxonomyItem} />;
        case "card4":
          return <CardCategory4 taxonomy={taxonomyItem} />;
        case "card5":
          return <CardCategory5 taxonomy={taxonomyItem} />;
        default:
          return <CardCategory3 taxonomy={taxonomyItem} />;
      }
    })();

    return (
      <li
        key={index}
        className={`glide__slide ${itemClassName}`}
        onClick={() => handleCardClick(item)}
      >
        {cardComponent}
      </li>
    );
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>

        {!isDataReady ? (
          // Render skeletons based on screen size
          <ul className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-5">
            {renderSkeletons()}
          </ul>
        ) : (
          // Render Glide slider only when data is ready
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {sortedVenues.map((item, index) => renderCard(item, index))}
            </ul>
          </div>
        )}

        {sliderStyle === "style2" && <NextPrev className="justify-center mt-16" />}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
