import React, { FC } from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import imageWebp from "images/hero-right.webp";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface SectionHeroProps {
  className?: string;
  onButtonClick?: () => void;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "", onButtonClick }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      {/* Preload the WebP image */}
      <Helmet>
        <link rel="preload" href={imageWebp} as="image" type="image/webp" />
      </Helmet>

      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-6xl !leading-[114%]">
            Pakistan's First Online <span className="text-custom-color">Event</span> Booking Platform.
          </h2>
          <span className="text-base md:text-md text-neutral-500 dark:text-neutral-400">
            Explore our Exclusively handpicked Venues, Caterers, and Decorators. Your one-stop solution for seamless event booking.
          </span>
          {/* <ButtonPrimary onClick={onButtonClick}>Featured Venues</ButtonPrimary> */}
        </div>
        <div className="flex-grow">
          <picture>
            {/* Use the WebP format first, and fall back to PNG if WebP is not supported */}
            <source srcSet={imageWebp} type="image/webp" />
            {/* Specify the width and height attributes */}
            <LazyLoadImage
              className="w-full"
              src={imagePng}
              alt="hero"
              width={800} // replace with actual width
              height={600} // replace with actual height
              loading="eager"
            />
          </picture>
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
