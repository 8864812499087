import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { BACKEND_URL } from "backendUrl";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify for notifications
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import axios from "axios"; // Import Axios for HTTP requests

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "💌 EMAIL",
    desc: "info@tayaripk.com",
    icon: "las la-envelope",
  },
  {
    title: "☎ PHONE",
    desc: "+92 304 3329988",
    icon: "las la-phone",
  },
  {
    title: "📲 Whatsapp",
    desc: "+92 304 3329988",
    icon: "las la-message",
  },
  {
    title: "📍 ADDRESS",
    desc: "Top City 1, Islamabad, Pakistan",
    icon: "las la-map-marker-alt",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  // Define state variables for form fields
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Send a POST request to the backend
      const response = await axios.post(`${BACKEND_URL}contact`, {
        fullName,
        email,
        message,
      });

      // Notify success
      toast.success("Message sent successfully!");

      // Reset form fields
      setFullName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Please try again later.");
    }
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>tayari. | Contact Us</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <div>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      {item.title}
                    </h3>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      {item.desc}
                    </span>
                  </div>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                    className="mt-1"
                    rows={6}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer for displaying notifications */}
    </div>
  );
};

export default PageContact;
