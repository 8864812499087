import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import React, { Fragment, useState, useEffect } from "react";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import { Place } from 'data/types';

const SearchDropdown = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const { allVenues, setSelectedVenue } = useSearchContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredVenues, setFilteredVenues] = useState<Place[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm) {
      const results = allVenues.filter((venue) =>
        venue.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredVenues(results);
    } else {
      setFilteredVenues([]);
    }
  }, [searchTerm, allVenues]);

  const handleSelectVenue = (venue: Place, closePopover: () => void) => {
    setSelectedVenue(venue);
    navigate("/listing-stay");
    closePopover();
  };

  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open, close }) => {
          if (open) {
            setTimeout(() => {
              inputRef.current?.focus();
            }, 100);
          }

          return (
            <>
              <Popover.Button className="text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
                <i className="las la-search"></i>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute right-0 z-10 w-screen max-w-sm mt-3"
                >
                  <form action="" method="POST" onSubmit={(e) => e.preventDefault()}>
                    <Input
                      ref={inputRef}
                      type="search"
                      placeholder="Type and press enter"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <input type="submit" hidden value="" />
                  </form>
                  {filteredVenues.length > 0 && (
                    <div className="mt-2 bg-white shadow-lg rounded-lg">
                      {filteredVenues.map((venue) => (
                        <div
                          key={venue.id}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleSelectVenue(venue, close)}
                        >
                          {venue.name}
                        </div>
                      ))}
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

export default SearchDropdown;
