// StayCardSkeleton.tsx
import React from "react";

const StayCardSkeleton = () => {
  return (
    <div
      className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform lg:hover:shadow-xl md:hover:shadow-xl shadow-none transition-shadow cursor-pointer"
      data-nc-id="StayCardSkeleton"
    >
      {/* Image Skeleton - No gap between image and content */}
      <div className="relative w-full aspect-w-4 aspect-h-3 bg-gray-300 animate-pulse"></div>
      
      {/* Content Skeleton - No padding at the top to eliminate gaps */}
      <div className="p-4 space-y-4">
        {/* Placeholder for menus or options count */}
        <div className="h-4 bg-gray-300 rounded w-1/4 animate-pulse"></div>

        {/* Placeholder for title */}
        <div className="h-5 bg-gray-300 rounded w-3/4 animate-pulse"></div>

        {/* Placeholder for address or capacity */}
        <div className="h-4 bg-gray-300 rounded w-full animate-pulse"></div>

        {/* Divider line */}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>

        {/* Bottom row with price and rating */}
        <div className="flex justify-between items-center">
          {/* Price Skeleton */}
          <div className="h-5 bg-gray-300 rounded w-1/3 animate-pulse"></div>

          {/* Rating Skeleton */}

        </div>
      </div>
    </div>
  );
};

export default StayCardSkeleton;
