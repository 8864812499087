import React, { FC } from "react";
import { Review } from "data/types";
import { StarIcon } from "@heroicons/react/24/solid";
import Avatar from "shared/Avatar/Avatar";

export interface CardAuthorBoxProps {
  className?: string;
  author: Review;
  index?: number;
}

const CardAuthorBox: FC<CardAuthorBoxProps> = ({
  className = "",
  author,
  index,
}) => {
  const { username, userProfilePicture, rating, description } = author;
  return (
    <div
      className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox"
    >
      {/* <Avatar
        sizeClass="w-20 h-20 text-2xl"
        radius="rounded-full"
        imgUrl={userProfilePicture}
        userName={username}
      /> */}
      <div className="mt-3">
        <h2 className={`text-lg font-semibold`}>
          <span className="line-clamp-1">{username}</span>
        </h2>
        <span
          className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          {description}
        </span>
      </div>
      <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <span className="text-xs font-medium pt-[1px]">{rating}</span>
        <StarIcon className="w-5 h-5 text-amber-500 ml-2 " />
      </div>
    </div>
  );
};

export default CardAuthorBox;
