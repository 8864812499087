import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSearchContext, SearchTab } from "context/search"; // Ensure SearchTab is imported correctly
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import SyncLoader from "react-spinners/SyncLoader";


export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({ className = "" }) => {
  const [searchParams] = useSearchParams();
  const { setLocation, setGuests, setDates, setSelectedType, searchPlaces, searchResults } = useSearchContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const location = searchParams.get("location") ?? "";
    const guests = parseInt(searchParams.get("guests") ?? "0", 10);
    const startDate = new Date(searchParams.get("startDate") ?? "");
    const type = (searchParams.get("selectedType") as SearchTab) || "Venues";

    if (location && !isNaN(guests) && startDate.toString() !== "Invalid Date") {
      setLocation(location);
      setGuests(guests);
      setDates({ startDate, endDate: startDate });
      setSelectedType(type);

      setLoading(true);
      searchPlaces().finally(() => setLoading(false));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchResults || searchResults.length === 0) {
      setLoading(true);
      searchPlaces().finally(() => setLoading(false));
    }
  }, [searchResults, searchPlaces]);

  if (loading) {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <SyncLoader color={"#ffd02b"} loading={loading} size={15} margin={2} />
    </div>
  );
}

  return (
    <div className={`nc-ListingStayMapPage relative ${className}`} data-nc-id="ListingStayMapPage">
      <Helmet>
        <title>tayari. | Search Results</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
        <SectionHeroArchivePage currentPage="Venues" currentTab="Venues" />
      </div>
      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap />
      </div>
    </div>
  );
};

export default ListingStayMapPage;
