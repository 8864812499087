import React from "react";
import { Helmet } from "react-helmet";

const TermsAndConditions: React.FC = () => {
  return (
    <div
      data-nc-id="TermsAndConditions"
      className="bg-white dark:bg-neutral-900 mt-6 min-h-screen flex items-center justify-center text-center lg:p-0 p-4"
    >
      <Helmet>
        <title>tayari. | Terms and Conditions</title>
      </Helmet>
      <div className="max-w-4xl mx-auto text-left">
        <h1 className="text-4xl font-bold mb-8">Terms and Conditions</h1>

        <section className="mb-6">
          <ol className="list-decimal list-inside space-y-4 text-neutral-700 dark:text-neutral-300">
            <li>
              Advance payment is required when you make a booking. Full payment
              is required after the event or within 24 hours.
            </li>
            <li>
              All prices quoted are exclusive of applicable taxes, which will be
              added to your total amount due.
            </li>
            <li>
              Payment methods accepted include Debit/Credit Cards or Bank
              Transfer.
            </li>
            <li>
              Cancellations made within 24 hours of the booking date will
              receive a full refund.
            </li>
            <li>
              Cancellations made after 24 hours of the booking date will not be
              eligible for a refund.
            </li>
            <li>
              To cancel your booking, please contact us at +92 323 3329988.
            </li>
            <li>
              The venue is available for use during the hours specified in your
              booking confirmation.
            </li>
            <li>
              Any additional time beyond the booked hours will be charged at the
              applicable rate. The venue must be returned in the condition it
              was provided. Any damages or excessive cleaning required will
              result in additional charges.
            </li>
            <li>
              tayari. is not liable for any loss, damage, or injury sustained by
              the client or any attendees during the use of the venue.
            </li>
            <li>
              Clients are responsible for the behavior of their guests and any
              damage to the venue or its contents. Clients must adhere to all
              venue rules and regulations, including noise restrictions,
              occupancy limits, and any other local laws and ordinances.
            </li>
            <li>
              Any illegal activities are strictly prohibited and will result in
              immediate termination of the event without refund. tayari. is not
              liable for any failure to perform its obligations due to events
              beyond its reasonable control, including but not limited to acts
              of God, war, strikes, pandemics, or government restrictions.
            </li>
            <li>
              These terms and conditions are governed by the laws of Pakistan,
              and any disputes will be subject to the jurisdiction of the courts
              of Pakistan.
            </li>
            <li>
              By proceeding with your booking, you acknowledge that you have
              read, understood, and agree to be bound by these terms and
              conditions.
            </li>
            <li>Thank you for choosing tayari. We look forward to hosting your event.</li>
          </ol>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
