import React, { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { useNavigate } from "react-router-dom";
import { useSearchContext } from "context/search";
import { useFirebase } from "context/firebase";
import { Place, SavedPlace } from 'data/types';
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { BACKEND_URL } from "backendUrl";
import axios from "axios";

export interface StayCardHProps {
  className?: string;
  data: Place;
}

const StayCardH: FC<StayCardHProps> = ({ className = "", data }) => {
  const { images, address, name, id, rating, userRatingCount, halls, google_images } = data;
  const { selectedVenue, setSelectedVenue, savePlace, removePlace, savedVenues, selectedType } = useSearchContext();
  const navigate = useNavigate();
  const { getCurrentUser } = useFirebase();
  const [isSaved, setIsSaved] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupColorClass, setPopupColorClass] = useState("bg-teal-800");

  useEffect(() => {
    console.log("selected venue changed:", selectedVenue);
  }, [selectedVenue]);

  const handleCardClick = () => {
    setSelectedVenue(data);
    navigate(`/listing-vendor/${data.name}/${data.type}/${data.id}`); // Ensure this uses the correct MongoDB _id
  };

  useEffect(() => {
    const checkIfSaved = async () => {
      const user = getCurrentUser();
      if (user) {
        const response = await axios.get(`${BACKEND_URL}saved-places/${user.uid}`);
        const savedPlaceIds = response.data.map((savedPlace: SavedPlace) => savedPlace.place_id);
        setIsSaved(savedPlaceIds.includes(data.id));
      }
    };
    checkIfSaved();
  }, [data, getCurrentUser, savedVenues]);

  const showPopup = (message: string, colorClass = "bg-teal-800") => {
    setPopupMessage(message);
    setPopupColorClass(colorClass);
    setIsPopupVisible(true);
    setTimeout(() => {
      setIsPopupVisible(false);
    }, 3000); // Hide after 3 seconds
  };

  const handleSavePlace = async (placeId: string) => {
    const user = getCurrentUser();
    if (user) {
      await savePlace(placeId, user.uid);
      setIsSaved(true);
      showPopup("Added to wishlist!");
    } else {
      showPopup("Please log in first", "bg-red-600");
    }
  };

  const handleRemovePlace = async (placeId: string) => {
    const user = getCurrentUser();
    if (user) {
      await removePlace(placeId, user.uid);
      setIsSaved(false);
      showPopup("Removed from wishlist!");
    } else {
      showPopup("Please log in first", "bg-red-600");
    }
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={images.length ? images : google_images}
          uniqueID={`StayCardH_${id}`}
        />
        <div onClick={() => isSaved ? handleRemovePlace(data.id) : handleSavePlace(data.id)}>
          <BtnLikeIcon className="absolute right-3 top-3" isLiked={isSaved} />
        </div>
      </div>
    );
  };
  
  const renderTienIchh = () => {
    const defaultAmenities = {
      Venues: [
        { name: "Wifi", icon: "las la-wifi" },
        { name: "Smoking", icon: "las la-smoking" },
        { name: "2 Washrooms", icon: "las la-restroom" },
        { name: "Fire Exit", icon: "las la-fire-extinguisher" },
      ],
      Caterers: [
        { name: "Vegetarian Options", icon: "las la-leaf" },
        { name: "Non-Vegetarian Options", icon: "las la-drumstick-bite" },
        { name: "Desserts", icon: "las la-ice-cream" },
        { name: "Beverages", icon: "las la-coffee" },
      ],
      Decorators: [
        { name: "Lighting", icon: "las la-lightbulb" },
        { name: "Flower Decor", icon: "las la-spa" },
        { name: "Thematic Decor", icon: "las la-paint-brush" },
        { name: "Seating", icon: "las la-chair" },
      ],
      Photographers: [
        { name: "Photo Editing", icon: "las la-camera-retro" },
        { name: "Videography", icon: "las la-video" },
        { name: "Drone Shots", icon: "las la-drone" },
        { name: "Event Coverage", icon: "las la-film" },
      ],
    };
  
    const amenities = data?.amenities && data?.amenities.length > 0
      ? data.amenities
      : defaultAmenities[selectedType as keyof typeof defaultAmenities];
  
    const maxGuests = Math.max(...halls.map((hall) => hall.seating_capacity));
  
    return (
      <div>
        <div className="hidden sm:grid grid-cols-1 gap-4 sm:grid-cols-3">
          <div className="flex items-center space-x-2">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              Upto {maxGuests} Guests
            </span>
          </div>
          {amenities.map((item, index) => (
            <div key={index} className="flex items-center space-x-2 text-ellipsis">
              {item.icon.startsWith('http') || item.icon.startsWith('data:image') ? (
                <img src={item.icon} alt={item.name} className="w-4 h-4" />
              ) : (
                <i className={`text-lg ${item.icon}`}></i>
              )}
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  
  const renderContent = () => {
    // Determine the lowest price from halls' prices and base prices, defaulting to Infinity if no prices are available
    let hallPrices = halls.map(hall => hall.price || Infinity);
    let basePrices = halls.map(hall => hall.basePrice || Infinity);
    let lowestPrice = Math.min(...hallPrices, ...basePrices);
  
    // Determine the lowest price from menus if the venue is menu-based and has menus available
    if (data.menu_based && Array.isArray(data.menus) && data.menus.length > 0) {
      const lowestMenuPrice = Math.min(...data.menus.map(menu => menu.price || Infinity));
      lowestPrice = Math.min(lowestPrice, lowestMenuPrice); // Compare with hall prices and base prices if applicable
    }
  
    // Check if lowestPrice is Infinity, which means no valid price was found
    const displayPrice = lowestPrice === Infinity ? 'N/A' : `PKR ${lowestPrice.toLocaleString()}`;
  
    const maxGuests = Math.max(...halls.map(hall => hall.seating_capacity || 0));
  
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
        </div>
        <div className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1 max-h-5 overflow-hidden">
          <span>{address}</span>
        </div>
  
        <div className="lg:hidden flex items-center justify-start space-x-1 mt-4 ">
          <i className="las la-user text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            Upto {maxGuests} Guests
          </span>
        </div>
  
        <div className="lg:flex hidden w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIchh()}
  
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold text-secondary-500">
            Starting from {displayPrice}
          </span>
          <StartRating reviewCount={userRatingCount} point={rating} />
        </div>
      </div>
    );
  };
  
  
  
  
  

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden lg:hover:shadow-xl md:hover:shadow-xl shadow-none transition-shadow will-change-transform cursor-pointer ${className}`}
      data-nc-id="StayCardH"
    >
      <div className="grid grid-cols-1 md:flex md:flex-row">
        {renderSliderGallery()}
        <div onClick={handleCardClick}>
          {renderContent()}
        </div>
      </div>

      <div className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 p-2 ${popupColorClass} lg:w-3/4 w-1/2 text-center text-white rounded transition-opacity duration-200 ease-in-out ${isPopupVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        {popupMessage}
      </div>
    </div>
  );
};

export default StayCardH;
