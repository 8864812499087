// SectionGridFeaturePlaces.tsx
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StayCard from "components/StayCard/StayCard";
import HeaderFilter from "./HeaderFilter"; // Import HeaderFilter
import { useSearchContext } from "context/search";
import { Place } from "data/types"; // Ensure the Place type is imported
import StayCardSkeleton from "./StayCardSkeletonn";

export interface SectionGridFeaturePlacesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "Explore Our Vendors",
  subHeading = "Explore Our Curated Selection Of Trusted Vendors And Partners",
  headingIsCenter,
  tabs = ["Islamabad", "Lahore", "Karachi", "Peshawar"],
}) => {
  const { allVenues, getAllVenues, setLocation, searchPlaces } = useSearchContext();
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [isDataReady, setIsDataReady] = useState(false); // Add state to manage data readiness
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVenues = async () => {
      await getAllVenues();
      setIsDataReady(true);
    };

    fetchVenues();
  }, [getAllVenues]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const filteredVenues = allVenues.filter(venue =>
    venue.address.toLowerCase().includes(activeTab.toLowerCase())
  );

  const handleViewAllClick = async () => {
    const location = `${activeTab}, Pakistan`;
    setLocation(location);
    await searchPlaces({ location });
    navigate("/listing-stay-map");
  };

  const renderCard = (venue: Place) => {
    return <StayCard key={venue.id} data={venue} isHomePage={true} />; // Pass isHomePage as true
  };

  const renderSkeletons = () => {
    const skeletonCount = filteredVenues.length > 0 ? filteredVenues.length : 8; // Render skeletons based on expected data
    return Array.from({ length: skeletonCount }).map((_, index) => (
      <StayCardSkeleton key={index} />
    ));
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={activeTab}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={handleTabClick}
        viewAllHandler={handleViewAllClick} // Pass viewAllHandler prop
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {isDataReady
          ? filteredVenues.map(venue => renderCard(venue))
          : renderSkeletons()} {/* Render skeletons when data is loading */}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <ButtonPrimary>Show me more</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
