import React, { createContext, useState, FC, ReactNode, useEffect, useCallback } from "react";
import axios from "axios";
import { BACKEND_URL } from "backendUrl";
import { Hall, Place, SavedPlace, Review, Menu } from 'data/types';
import { useFirebase } from "context/firebase";

export type SearchTab = "Venues" | "Caterers" | "Decorators" | "Photographers";

interface SearchContextType {
  location: string;
  setLocation: (location: string) => void;
  guests: number;
  setGuests: (guests: number) => void;
  dates: { startDate: Date | null; endDate: Date | null };
  setDates: (dates: { startDate: Date | null; endDate: Date | null }) => void;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  highlightDates: { date: Date, type: 'day' | 'night' | 'fullyBooked' }[];
  setHighlightDates: (dates: { date: Date, type: 'day' | 'night' | 'fullyBooked' }[]) => void;
  searchResults: Place[];
  setSearchResults: (results: Place[]) => void;
  searchPlaces: (params?: {
    location?: string;
    guests?: number;
    startDate?: Date | null;
    endDate?: Date | null;
  }) => Promise<void>;
  city: string;
  country: string;
  filterResultsByPrice: (minPrice: number, maxPrice: number) => void;
  filteredResults: Place[];
  selectedVenue: Place | null;
  setSelectedVenue: (venue: Place | null) => void;
  selectedMenu: Menu | null;
  setSelectedMenu: (menu: Menu | null) => void;
  selectedHall: Hall | null;
  setSelectedHall: (hall: Hall | null) => void;
  basePrice: number; // Added basePrice state
  setBasePrice: (price: number) => void; // Added setBasePrice setter
  featuredVenues: Place[];
  getFeaturedVenues: () => Promise<void>;
  allVenues: Place[];
  getAllVenues: () => Promise<void>;
  reviews: Review[];
  getReviews: () => Promise<void>;
  savedVenues: Place[];
  getSavedVenues: (userId: string) => Promise<void>;
  savePlace: (placeId: string, userId: string) => Promise<void>;
  removePlace: (placeId: string, userId: string) => Promise<void>;
  hallDayBooked: boolean;
  setHallDayBooked: (booked: boolean) => void;
  hallNightBooked: boolean;
  setHallNightBooked: (booked: boolean) => void;
  selectedType: SearchTab;
  setSelectedType: (type: SearchTab) => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const getLocationParts = (location: string) => {
  const parts = location.split(',').map(part => part.trim());
  return {
    city: parts.length > 1 ? parts[parts.length - 2] : '',
    country: parts.length > 0 ? parts[parts.length - 1] : ''
  };
};

export const SearchProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [location, setLocation] = useState<string>(() => {
    return localStorage.getItem("location") || "";
  });

  const [guests, setGuests] = useState<number>(() => {
    return parseInt(localStorage.getItem("guests") || "50");
  });

  const [dates, setDates] = useState<{ startDate: Date | null; endDate: Date | null }>(() => {
    const startDate = localStorage.getItem("startDate");
    const endDate = localStorage.getItem("endDate");
    return {
      startDate: startDate ? new Date(startDate) : null,
      endDate: endDate ? new Date(endDate) : null
    };
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [highlightDates, setHighlightDates] = useState<{ date: Date, type: 'day' | 'night' | 'fullyBooked' }[]>([]);

  const [searchResults, setSearchResults] = useState<Place[]>(() => {
    const storedResults = localStorage.getItem("searchResults");
    return storedResults ? JSON.parse(storedResults) : [];
  });

  const [filteredResults, setFilteredResults] = useState<Place[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<Place | null>(() => {
    const storedVenue = localStorage.getItem("selectedVenue");
    return storedVenue ? JSON.parse(storedVenue) : null;
  });

  const [selectedHall, setSelectedHall] = useState<Hall | null>(() => {
    const storedHall = localStorage.getItem("selectedHall");
    return storedHall ? JSON.parse(storedHall) : null;
  });

  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(() => {
    const storedMenu = localStorage.getItem("selectedMenu");
    return storedMenu ? JSON.parse(storedMenu) : null;
  });

  const [basePrice, setBasePrice] = useState<number>(0); // Added basePrice state

  const [featuredVenues, setFeaturedVenues] = useState<Place[]>([]);
  const [allVenues, setAllVenues] = useState<Place[]>([]);

  const [reviews, setReviews] = useState<Review[]>([]);

  const [savedVenues, setSavedVenues] = useState<Place[]>([]);

  const [hallDayBooked, setHallDayBooked] = useState<boolean>(false);
  const [hallNightBooked, setHallNightBooked] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<SearchTab>("Venues");

  const { city, country } = getLocationParts(location);

  const { getCurrentUser } = useFirebase();

  useEffect(() => {
    console.log("Location updated:", location);
    localStorage.setItem("location", location);
  }, [location]);

  useEffect(() => {
    console.log("Guests updated:", guests);
    localStorage.setItem("guests", guests.toString());
  }, [guests]);

  useEffect(() => {
    console.log("Dates updated:", dates);
    if (dates.startDate) {
      localStorage.setItem("startDate", dates.startDate.toISOString());
    } else {
      localStorage.removeItem("startDate");
    }
    if (dates.endDate) {
      localStorage.setItem("endDate", dates.endDate.toISOString());
    } else {
      localStorage.removeItem("endDate");
    }
  }, [dates]);

  useEffect(() => {
    console.log("Search results updated:", searchResults);
    localStorage.setItem("searchResults", JSON.stringify(searchResults));
  }, [searchResults]);

  useEffect(() => {
    console.log("Selected venue updated:", selectedVenue);
    localStorage.setItem("selectedVenue", JSON.stringify(selectedVenue));
  }, [selectedVenue]);

  useEffect(() => {
    console.log("Selected hall updated:", selectedHall);
    localStorage.setItem("selectedHall", JSON.stringify(selectedHall));
  }, [selectedHall]);

  useEffect(() => {
    console.log("Selected menu updated:", selectedMenu);
    localStorage.setItem("selectedMenu", JSON.stringify(selectedMenu));
  }, [selectedMenu]);

  useEffect(() => {
    console.log("Base price updated:", basePrice);
    localStorage.setItem("basePrice", basePrice.toString());
  }, [basePrice]);

  const searchPlaces = useCallback(async (params?: { location?: string; guests?: number; startDate?: Date | null; endDate?: Date | null }) => {
    console.log("searchPlaces called with params:", params);

    const searchLocation = params?.location || location;
    const searchGuests = params?.guests !== undefined ? params.guests : guests;
    const searchStartDate = params?.startDate !== undefined ? params.startDate : dates.startDate;
    const searchEndDate = params?.endDate !== undefined ? params.endDate : dates.endDate;

    const extractCityFromLocation = (location: string) => {
      const parts = location.split(',').map(part => part.trim());
      return parts.length > 1 ? parts[parts.length - 2].toLowerCase() : parts[0].toLowerCase();
    };

    const searchCity = extractCityFromLocation(searchLocation);
    console.log("Filtering venues with city:", searchCity);

    try {
      const filteredVenues = allVenues
        .filter(place => {
          const placeCity = extractCityFromLocation(place.address);
          console.log("selected type.............", selectedType);
          // Include caterers even if they don't have halls
          return placeCity.includes(searchCity) && place.type && place.type.toLowerCase() === selectedType.toLowerCase();
        })
        // If the selected type is not Caterers, ensure halls exist, otherwise skip this filtering step
        .filter(place => selectedType === "Caterers" || (place.halls && place.halls.length > 0))
        .map(place => {
          // If the place has halls, find the closest hall, otherwise leave closestHallCapacity undefined
          if (place.halls && place.halls.length > 0) {
            const closestHall = place.halls.reduce((prev, curr) => {
              return Math.abs(curr.seating_capacity - searchGuests) < Math.abs(prev.seating_capacity - searchGuests) ? curr : prev;
            });

            return { ...place, closestHallCapacity: closestHall.seating_capacity };
          }
          // Return place as-is for caterers without halls
          return { ...place, closestHallCapacity: 0 };
        })
        // Sort only if the closest hall capacity exists (skip sorting for caterers without halls)
        .sort((a, b) => {
          if (a.closestHallCapacity && b.closestHallCapacity) {
            const diffA = Math.abs(a.closestHallCapacity - searchGuests);
            const diffB = Math.abs(b.closestHallCapacity - searchGuests);
            return diffA - diffB || a.closestHallCapacity - b.closestHallCapacity;
          }
          return 0; // For caterers, don't apply any sorting based on hall capacity
        });

      setSearchResults(filteredVenues);
      console.log("Filtered venues:", filteredVenues);
    } catch (error) {
      console.error("Error searching for places:", error);
    }
  }, [location, guests, dates.startDate, dates.endDate, allVenues, selectedType]);


  const filterResultsByPrice = (minPrice: number, maxPrice: number) => {
    console.log(`filterResultsByPrice called with range: ${minPrice} - ${maxPrice}`);
    const filtered = searchResults.filter(place =>
      place.halls.some((hall: Hall) => hall.price >= minPrice && hall.price <= maxPrice)
    );
    console.log('Filtered results by price:', filtered);
    setFilteredResults(filtered);
  };

  const getFeaturedVenues = useCallback(async () => {
    console.log("getFeaturedVenues called");
    try {
      const response = await axios.get(`${BACKEND_URL}featured-places`);
      setFeaturedVenues(response.data);
      console.log("Fetched featured venues:", response.data);
    } catch (error) {
      console.error('Error fetching featured venues:', error);
    }
  }, []);

  const getAllVenues = useCallback(async () => {
    console.log("getAllVenues called");
    try {
      const response = await axios.get(`${BACKEND_URL}places`);
      setAllVenues(response.data);
      console.log("Fetched all venues:", response.data);
    } catch (error) {
      console.error('Error fetching all venues:', error);
    }
  }, []);

  const getReviews = useCallback(async () => {
    console.log("getReviews called");
    try {
      const response = await axios.get(`${BACKEND_URL}reviews`);
      setReviews(response.data);
      console.log("Fetched reviews:", response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  }, []);

  const getSavedVenues = async (userId: string) => {
    console.log("getSavedVenues called for user:", userId);
    try {
      const response = await axios.get(`${BACKEND_URL}saved-places/${userId}`);
      const savedPlaceIds = response.data.map((savedPlace: SavedPlace) => savedPlace.place_id);
      const filteredVenues = allVenues.filter(venue => savedPlaceIds.includes(venue.id));
      setSavedVenues(filteredVenues);
      console.log("Fetched saved venues:", filteredVenues);
    } catch (error) {
      console.error('Error fetching saved places:', error);
    }
  };

  const savePlace = async (placeId: string, userId: string) => {
    console.log("savePlace called for placeId:", placeId, "and userId:", userId);
    try {
      await axios.post(`${BACKEND_URL}saved-places`, { place_id: placeId, user_firebase_id: userId });
      console.log("Place saved successfully");
      await getSavedVenues(userId);
    } catch (error) {
      console.error('Error saving place:', error);
    }
  };

  const removePlace = async (placeId: string, userId: string) => {
    console.log("removePlace called for placeId:", placeId, "and userId:", userId);
    try {
      await axios.delete(`${BACKEND_URL}saved-places`, {
        data: { place_id: placeId, user_firebase_id: userId }
      });
      console.log("Place removed successfully");
      await getSavedVenues(userId);
    } catch (error) {
      console.error('Error removing place:', error);
    }
  };

  useEffect(() => {
    getAllVenues();
    getFeaturedVenues();
    getReviews();
  }, [getAllVenues, getFeaturedVenues, getReviews]);

  return (
    <SearchContext.Provider
      value={{
        location,
        setLocation,
        guests,
        setGuests,
        dates,
        setDates,
        selectedDate,
        setSelectedDate,
        highlightDates,
        setHighlightDates,
        searchResults,
        setSearchResults,
        searchPlaces,
        city,
        country,
        filterResultsByPrice,
        filteredResults,
        selectedVenue,
        setSelectedVenue,
        selectedHall,
        setSelectedHall,
        selectedMenu,
        setSelectedMenu,
        basePrice, // Provided basePrice in context
        setBasePrice, // Provided setBasePrice in context
        featuredVenues,
        getFeaturedVenues,
        allVenues,
        getAllVenues,
        reviews,
        getReviews,
        savedVenues,
        getSavedVenues,
        savePlace,
        removePlace,
        hallDayBooked,
        setHallDayBooked,
        hallNightBooked,
        setHallNightBooked,
        selectedType,
        setSelectedType
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = React.useContext(SearchContext);
  if (!context) {
    throw new Error("useSearchContext must be used within a SearchProvider");
  }
  return context;
};
