import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useFirebase } from "context/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { getAuth } = useFirebase();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Stop loading when the user data is set
    });
    return () => unsubscribe();
  }, [getAuth]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/"); // Redirect to login page after logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (loading) {
    return (
      <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
        <Helmet>
          <title>tayari. | Account </title>
        </Helmet>
        <CommonLayout>
          <div className="space-y-6 sm:space-y-8">
            <h2 className="text-3xl font-semibold">Loading...</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex flex-col items-center justify-center">
              <div className="loader">Loading...</div>
            </div>
          </div>
        </CommonLayout>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
        <Helmet>
          <title>tayari. | Account </title>
        </Helmet>
        <CommonLayout>
          <div className="space-y-6 sm:space-y-8 min-h-screen">
            <h2 className="text-3xl font-semibold">Account Information</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 "></div>
            <div className="flex flex-col items-start justify-center">
              <p className="text-lg text-red-600">User not logged in</p>
            </div>
            <div
              className="flex flex-col items-start justify-start"
              onClick={() => navigate("/login")}
            >
              <button
                className="bg-red-500 text-white py-2 px-4 rounded"
              >
                Login
              </button>
            </div>
          </div>
        </CommonLayout>
      </div>
    );
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>tayari. | Account </title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <h2 className="text-3xl font-semibold">Account Information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <Avatar sizeClass="w-32 h-32" imgUrl={user.photoURL} />
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <label className="block text-sm font-medium text-neutral-700">Email</label>
                <p className="mt-1.5 text-lg">{user.email}</p>
              </div>
              <div className="mt-4 flex justify-start md:justify-start">
                <button
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
