import React, { createContext, useContext, ReactNode } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  onAuthStateChanged,
  Auth,
  UserCredential,
  User,
} from 'firebase/auth';
import axios from 'axios';
import { BACKEND_URL } from 'backendUrl';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase authentication
const firebaseAuth = getAuth(firebaseApp);
const firebaseGoogleAuthProvider = new GoogleAuthProvider();

// Define Firebase context type
type FirebaseContextType = {
  signupUserWithEmailAndPassword: (
    email: string,
    password: string,
    displayName?: string
  ) => Promise<Auth | UserCredential>;
  signinWithGoogle: () => Promise<Auth | UserCredential>;
  signinUser: (email: string, password: string) => Promise<Auth | UserCredential>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  sendVerificationEmail: (user: User) => Promise<void>;
  checkEmailVerification: (user: User) => Promise<boolean>;
  isUserVerified: () => Promise<boolean>;
  getAuth: () => Auth;
  getCurrentUser: () => User | null;
  onAuthStateChanged: (callback: (user: User | null) => void) => void;
  authenticateGoogleCalendarIfVendor: () => Promise<void>;
};

// Create Firebase context
const FirebaseContext = createContext<FirebaseContextType | null>(null);

// Custom hook to use Firebase context
export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};

// Firebase provider component
type FirebaseProviderProps = {
  children: ReactNode;
};

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children }) => {

  const signupUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<UserCredential> => {
    try {
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      await authenticateGoogleCalendarIfVendor();
      return userCredential;
    } catch (error) {
      throw error;
    }
  };

  const signinWithGoogle = async (): Promise<UserCredential> => {
    try {
      const result = await signInWithPopup(firebaseAuth, firebaseGoogleAuthProvider);
      await authenticateGoogleCalendarIfVendor();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const signinUser = async (email: string, password: string): Promise<UserCredential> => {
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      await authenticateGoogleCalendarIfVendor();
      return userCredential;
    } catch (error) {
      throw error;
    }
  };

  const handleSendPasswordResetEmail = (email: string): Promise<void> => {
    return sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

  const sendVerificationEmail = async (user: User): Promise<void> => {
    try {
      await sendEmailVerification(user);
    } catch (error) {
      throw error;
    }
  };

  const checkEmailVerification = async (user: User): Promise<boolean> => {
    await user.reload();
    return user.emailVerified;
  };

  const isUserVerified = async (): Promise<boolean> => {
    const user = getCurrentUser();
    if (user) {
      await user.reload();
      return user.emailVerified;
    }
    return false;
  };

  const getAuthInstance = () => {
    return firebaseAuth;
  };

  const getCurrentUser = () => {
    return firebaseAuth.currentUser;
  };

  const subscribeToAuthStateChanged = (callback: (user: User | null) => void) => {
    onAuthStateChanged(firebaseAuth, callback);
  };

  const authenticateGoogleCalendarIfVendor = async () => {
    const user = getCurrentUser();
    if (!user) {
      throw new Error('User is not authenticated');
    }
  
    const userEmail = user.email;
  
    try {
      const vendorCheckResponse = await axios.post(`${BACKEND_URL}vendor/check`, { email: userEmail });
      if (vendorCheckResponse.data.isVendor) {
        // Fetch the vendor details by email
        const vendorResponse = await axios.get(`${BACKEND_URL}vendor-by-email`, {
          params: { email: userEmail }
        });
  
        const vendor = vendorResponse.data;
  
        if (vendor.permissions_taken) {
          console.log('Permissions already taken, no further action needed.');
        } else {
          const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=550079792515-kdc943q0tn61b3shtul5tihbqlsmd5mr.apps.googleusercontent.com&redirect_uri=https://tayaripk.com/callback&scope=https://www.googleapis.com/auth/calendar&access_type=offline&prompt=consent`;
          // const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=550079792515-kdc943q0tn61b3shtul5tihbqlsmd5mr.apps.googleusercontent.com&redirect_uri=http://localhost:5000/callback&scope=https://www.googleapis.com/auth/calendar&access_type=offline&prompt=consent`;
          // const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&scope=https://www.googleapis.com/auth/calendar&access_type=offline&prompt=consent`;
          window.location.href = authUrl;
        }
      } else {
        console.log('User is not a vendor');
      }
    } catch (error) {
      console.error('Error checking if user is a vendor or fetching vendor details:', error);
      throw error;
    }
  };

  const value: FirebaseContextType = {
    signupUserWithEmailAndPassword,
    signinWithGoogle,
    signinUser,
    sendPasswordResetEmail: handleSendPasswordResetEmail,
    sendVerificationEmail,
    checkEmailVerification,
    isUserVerified,
    getAuth: getAuthInstance,
    getCurrentUser,
    onAuthStateChanged: subscribeToAuthStateChanged,
    authenticateGoogleCalendarIfVendor,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
