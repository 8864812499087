import React, { FC, useEffect, useState, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import logoImg from "images/user.png";
import { useFirebase } from "context/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  HeartIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { getAuth } = useFirebase();
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [getAuth]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null); // Clear user state after logout
      navigate("/"); // Redirect to login page after logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SwitchDarkMode />
            <SearchDropdown />
            <div className="px-1" />
            {user ? (
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button className={`inline-flex mt-2 items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                      <img
                        className="h-10 w-10 rounded-full object-cover"
                        src={user.photoURL || logoImg}
                        alt="User"
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                        <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                            <Link
                              to="/account"
                              className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <UserCircleIcon
                                className="w-6 h-6 text-neutral-500 dark:text-neutral-300"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-sm font-medium">Account</p>
                              </div>
                            </Link>
                            <Link
                              to="/account-savelists"
                              className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <HeartIcon
                                className="w-6 h-6 text-neutral-500 dark:text-neutral-300"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-sm font-medium">Wishlists</p>
                              </div>
                            </Link>
                          </div>
                          <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                          <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                            <button
                              onClick={handleLogout}
                              className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <ArrowRightOnRectangleIcon
                                className="w-6 h-6 text-neutral-500 dark:text-neutral-300"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-sm font-medium text-red-500">Logout</p>
                              </div>
                            </button>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            ) : (
              <ButtonPrimary href="/login">Login</ButtonPrimary>
            )}
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
