import React, { FC, useState, useEffect } from "react";
import StayCard from "components/StayCard/StayCard";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading3 from "components/Heading/Heading3";
import { useSearchContext } from "context/search"; // Import the context
import { GoogleCalendarEvent } from "data/types";
import StayCardSkeleton from "containers/PageHome/StayCardSkeletonn";

export interface SectionGridFilterCardProps {
  className?: string;
  events: GoogleCalendarEvent[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  events,
}) => {
  const { selectedVenue, dates } = useSearchContext();
  const [loading, setLoading] = useState(true); // Add a loading state

  // Simulate loading time; replace with actual data fetching logic
  useEffect(() => {
    // Set loading to false after data fetch; replace this with actual data fetching
    const fetchData = async () => {
      // Simulate fetch delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (!selectedVenue) {
    return <div>No venue selected</div>;
  }

  const renderCards = () => {
    if (loading) {
      // Render StayCardSkeleton components when loading is true
      return Array.from({ length: selectedVenue.halls.length }).map((_, index) => (
        <StayCardSkeleton key={`skeleton-${index}`} />
      ));
    }

    

    // Render actual StayCard components when data is loaded
    return selectedVenue.halls.map((hall) => (
      <StayCard
        key={hall._id}
        data={hall}
        events={events}
        selectedDate={dates.startDate}
        cardType={selectedVenue.type}
        isPerPerson={selectedVenue.is_perperson}
      />
    ));
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading3 />

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {renderCards()}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
