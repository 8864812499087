import React from "react";
import { Helmet } from "react-helmet";

const RefundPolicy: React.FC = () => {
  return (
    <div
      data-nc-id="RefundPolicy"
      className="bg-white dark:bg-neutral-900 mt-6 min-h-screen flex items-center justify-center text-center lg:p-0 p-4"
    >
      <Helmet>
        <title>tayari. | Refund Policy</title>
      </Helmet>
      <div className="max-w-4xl mx-auto text-left">
        <h1 className="text-4xl font-bold mb-8">Refund Policy</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">1. Eligibility for Refunds</h2>
          <p className="mb-4">
            Customers are eligible for a full refund if the booking is canceled within 24 hours of the booking date. No refunds will be issued for cancellations made after this 24-hour window.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">2. Refund Process</h2>
          <p className="mb-4">
            To request a refund, please contact us at +92 323 3329988. Upon approval, refunds will be processed using the original payment method. It may take up to 10 business days for the refund to reflect in your account, depending on your bank or payment provider.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">3. Non-Refundable Situations</h2>
          <p className="mb-4">
            No refunds will be issued for cancellations made more than 24 hours after the booking date. Additionally, any additional time beyond the booked hours that is utilized and charged will not be eligible for a refund.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">4. Additional Charges</h2>
          <p className="mb-4">
            If the venue is not returned in the condition it was provided, or if excessive cleaning is required, additional charges may apply. These charges will be deducted from any refundable amount or charged separately if no refund is due.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">5. Contact Information</h2>
          <p className="mb-4">
            If you have any questions about our refund policy or need to request a refund, please contact us at:
          </p>
          <p className="mb-4">
            Phone: +92 323 3329988<br />
            Email: info@tayaripk.com<br />
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">6. Changes to This Refund Policy</h2>
          <p className="mb-4">
            Tayari reserves the right to modify this Refund Policy at any time. Any changes will be posted on our platform with an updated effective date. Continued use of our services after any changes indicates your acceptance of the revised policy.
          </p>
        </section>
      </div>
    </div>
  );
};

export default RefundPolicy;
