import React, { FC, useState, useRef } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useAddingPlaceContext } from "context/addingPlace";
import axios from "axios";
import { BACKEND_URL } from "backendUrl";
import { Hall, NewHall } from "data/types";

export interface PageAddListing2Props { }

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const { halls, setHalls, placeId, menuBased } = useAddingPlaceContext();
  const [hallName, setHallName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [advancePercentage, setAdvancePercentage] = useState<number>(0);
  const [seatingCapacity, setSeatingCapacity] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [indoor, setIndoor] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedVideos, setSelectedVideos] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [videoUrls, setVideoUrls] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // New states for hall pricing modes
  const [hallFixedPrice, setHallFixedPrice] = useState<boolean>(false);
  const [hallIsPerPerson, setHallIsPerPerson] = useState<boolean>(false);

  // New states for base price and range fields
  const [hasBasePrice, setHasBasePrice] = useState<boolean>(false);
  const [basePrice, setBasePrice] = useState<number>(0);
  const [hasRange, setHasRange] = useState<boolean>(false);
  const [priceRange, setPriceRange] = useState<string>("");

  // New states for adding amenities
  const [newAmenityName, setNewAmenityName] = useState<string>("");
  const [newAmenityIconFile, setNewAmenityIconFile] = useState<File | null>(null);
  const [amenitiesHall, setAmenitiesHall] = useState<{ name: string; icon: string }[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedImages(filesArray);
    }
  };

  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedVideos(filesArray);
    }
  };

  const handleAddAmenity = async () => {
    if (newAmenityName && newAmenityIconFile) {
      const formData = new FormData();
      formData.append("file", newAmenityIconFile);

      try {
        const response = await axios.post(`${BACKEND_URL}upload-image`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        const newAmenityIcon = response.data.imageUrl;

        setAmenitiesHall([...amenitiesHall, { name: newAmenityName, icon: newAmenityIcon }]); // Use setAmenitiesHall to update hall-specific amenities
        setNewAmenityName("");
        setNewAmenityIconFile(null);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
        setError(null);
      } catch (error) {
        console.error("Error uploading amenity icon:", error);
        setError("Error uploading amenity icon. Please try again.");
      }
    } else {
      setError("Please provide both name and icon for the amenity.");
    }
  };

  const handleAddHall = async () => {
    if (hallName) {
      if (!selectedImages.length && !selectedVideos.length) {
        setError("Please upload images or videos for the hall.");
        return;
      }
    } else {
      setError("Please add the hall name before proceeding.");
      return;
    }

    const fields = { price, advancePercentage, seatingCapacity, description };
    const emptyFields = Object.entries(fields).filter(([key, value]) => !value);

    if (emptyFields.length) {
      const confirmation = window.confirm(
        `You have left the following fields empty: ${emptyFields
          .map(([key]) => key)
          .join(", ")}. Do you still want to add the hall like this?`
      );

      if (!confirmation) {
        return; // User canceled the action
      }
    }
    setIsLoading(true);

    const uploadImagePromises = selectedImages.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      return axios.post(`${BACKEND_URL}upload-image`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data.imageUrl);
    });

    const uploadVideoPromises = selectedVideos.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      return axios.post(`${BACKEND_URL}upload-video`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => response.data.videoUrl);
    });

    try {
      const uploadedImageUrls = await Promise.all(uploadImagePromises);
      const uploadedVideoUrls = await Promise.all(uploadVideoPromises);

      const newHall: NewHall = {
        place: placeId,
        name: hallName,
        price,
        advance_percentage: advancePercentage,
        seating_capacity: seatingCapacity,
        images: uploadedImageUrls,
        description,
        videos: uploadedVideoUrls,
        indoor,
        hall_fixedPrice: hallFixedPrice,
        hall_isperperson: hallIsPerPerson,
        hasBasePrice,
        basePrice,
        hasRange,
        priceRange,
        amenities_hall: amenitiesHall,
      };

      setHalls([...halls, newHall as unknown as Hall]);
      setHallName("");
      setPrice(0);
      setAdvancePercentage(0);
      setSeatingCapacity(0);
      setSelectedImages([]);
      setDescription("");
      setImageUrls([]);
      setVideoUrls([]);
      setIndoor(false);
      setHallFixedPrice(false);
      setHallIsPerPerson(false);
      setHasBasePrice(false);
      setBasePrice(0);
      setHasRange(false);
      setPriceRange("");
      setAmenitiesHall([]);
      setError(null);

    } catch (error) {
      console.error("Error uploading images or videos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommonLayout index="02" nextHref="/add-listing-4" backtHref="/add-listing-1">
      <>
        <h2 className="text-2xl font-semibold">Add Halls</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <FormItem label="Hall name">
            <Input
              placeholder="Hall name"
              value={hallName}
              onChange={(e) => setHallName(e.target.value)}
            />
          </FormItem>

          <FormItem label="Price">
            <Input
              type="number"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
            />
          </FormItem>

          <FormItem label="Advance Percentage">
            <Input
              type="number"
              placeholder="Advance Percentage"
              value={advancePercentage}
              onChange={(e) => setAdvancePercentage(Number(e.target.value))}
            />
          </FormItem>

          <FormItem label="Seating capacity">
            <Input
              type="number"
              placeholder="Seating capacity"
              value={seatingCapacity}
              onChange={(e) => setSeatingCapacity(Number(e.target.value))}
            />
          </FormItem>

          <FormItem label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormItem>

          <FormItem label="Indoor">
            <input
              type="checkbox"
              checked={indoor}
              onChange={(e) => setIndoor(e.target.checked)}
            />
          </FormItem>

          <div className="flex space-x-4">
            <FormItem label="Fixed Price" desc="Is this hall priced at a fixed rate?">
              <input
                type="checkbox"
                checked={hallFixedPrice}
                onChange={(e) => setHallFixedPrice(e.target.checked)}
              />
            </FormItem>
            <FormItem label="Per Person Pricing" desc="Is this hall priced per person?">
              <input
                type="checkbox"
                checked={hallIsPerPerson}
                onChange={(e) => setHallIsPerPerson(e.target.checked)}
              />
            </FormItem>
          </div>

          <FormItem label="Has Base Price">
            <input
              type="checkbox"
              checked={hasBasePrice}
              onChange={(e) => setHasBasePrice(e.target.checked)}
            />
          </FormItem>

          {hasBasePrice && (
            <FormItem label="Base Price">
              <Input
                type="number"
                placeholder="Base Price"
                value={basePrice}
                onChange={(e) => setBasePrice(Number(e.target.value))}
              />
            </FormItem>
          )}

          <FormItem label="Has Range">
            <input
              type="checkbox"
              checked={hasRange}
              onChange={(e) => setHasRange(e.target.checked)}
            />
          </FormItem>

          {hasRange && (
            <FormItem label="Price Range">
              <Input
                placeholder="Price Range (e.g., 10-50)"
                value={priceRange}
                onChange={(e) => setPriceRange(e.target.value)}
              />
            </FormItem>
          )}

          <FormItem label="Images">
            <input type="file" multiple onChange={handleImageChange} />
            <div className="grid grid-cols-2 gap-4 mt-4">
              {selectedImages.map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  alt={`Selected ${index + 1}`}
                  className="object-cover w-full h-32 rounded-md"
                />
              ))}
            </div>
          </FormItem>

          <FormItem label="Videos">
            <input type="file" multiple onChange={handleVideoChange} />
            <div className="grid grid-cols-2 gap-4 mt-4">
              {selectedVideos.map((video, index) => (
                <video
                  key={index}
                  src={URL.createObjectURL(video)}
                  controls
                  className="object-cover w-full h-32 rounded-md"
                />
              ))}
            </div>
          </FormItem>

          {/* Amenity Addition Section */}
          <FormItem label="Amenities" desc="Add amenities with name and icon">
            <div className="space-y-4">
              <div className="flex space-x-4 items-center">
                <Input
                  placeholder="Amenity Name"
                  value={newAmenityName}
                  onChange={(e) => setNewAmenityName(e.target.value)}
                  className="flex-grow"
                />
                <input
                  type="file"
                  ref={inputRef}
                  onChange={(e) => {
                    if (e.target.files) {
                      setNewAmenityIconFile(e.target.files[0]);
                    }
                  }}
                  className="p-2 border border-neutral-200 rounded"
                />
                <button
                  onClick={handleAddAmenity}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <ul className="space-y-2">
                {amenitiesHall.map((amenity, index) => (
                  <li key={index} className="flex items-center space-x-2">
                    <img
                      src={amenity.icon}
                      alt={amenity.name}
                      width="20"
                      height="20"
                    />
                    <span>{amenity.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </FormItem>

          {error && <p className="text-red-500">{error}</p>}

          <ButtonSecondary onClick={handleAddHall} disabled={isLoading}>
            {isLoading ? "Uploading..." : "Add Hall"}
          </ButtonSecondary>

          {isLoading && <p className="text-red-500">Uploading images and videos, please wait...</p>}

          <div>
            <h3 className="text-xl font-semibold">Added Halls</h3>
            <ul>
              {halls.map((hall, index) => (
                <li key={index} className="mb-4">
                  <h4 className="font-medium">{hall.name}</h4>
                  <p>Price: ${hall.price}</p>
                  <p>Advance Percentage: {hall.advance_percentage}%</p>
                  <p>Seating capacity: {hall.seating_capacity} guests</p>
                  <p>Description: {hall.description}</p>
                  <p>Indoor: {hall.indoor ? 'Yes' : 'No'}</p>
                  <p>Pricing: {hall.hall_isperperson ? "Per Person" : "Fixed Price"}</p>
                  <p>Has Base Price: {hall.hasBasePrice ? "Yes" : "No"}</p>
                  <p>Base Price: {hall.hasBasePrice ? `$${hall.basePrice}` : "N/A"}</p>
                  <p>Has Range: {hall.hasRange ? "Yes" : "No"}</p>
                  <p>Price Range: {hall.hasRange ? hall.priceRange : "N/A"}</p>
                  <p>Images:</p>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {hall.images.map((image, i) => (
                      <img
                        key={i}
                        src={image}
                        alt={`Hall ${index + 1} ${i + 1}`}
                        className="object-cover w-full h-32 rounded-md"
                      />
                    ))}
                  </div>
                  <p className="mt-4">Videos:</p>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {hall.videos.map((video, i) => (
                      <video
                        key={i}
                        src={video}
                        controls
                        className="object-cover w-full h-32 rounded-md"
                      />
                    ))}
                  </div>
                  <p className="mt-4">Amenities:</p>
                  <ul className="space-y-2">
                    {hall.amenities_hall.map((amenity: { name: string; icon: string }, i: number) => (
                      <li key={i} className="flex items-center space-x-2">
                        <img src={amenity.icon} alt={amenity.name} width="20" height="20" />
                        <span>{amenity.name}</span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
