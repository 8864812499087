import DatePicker from "react-datepicker";
import React, { FC, useState, useEffect } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useSearchContext } from "context/search";

export interface DatesRangeInputProps {
  className?: string;
}

const DatesRangeInput: FC<DatesRangeInputProps> = ({
  className = "",
}) => {
  const { dates, setDates } = useSearchContext();
  const [startDate, setStartDate] = useState<Date | null>(dates.startDate);

  // Get today's date and set time to the beginning of the day
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    setStartDate(dates.startDate);
  }, [dates]);

  const onChangeDate = (date: Date | null) => {
    setStartDate(date);
    setDates({ startDate: date, endDate: date }); // Update context
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {`When's your Event?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          monthsShown={2}
          showPopperArrow={false}
          inline
          minDate={today} // Prevent selecting dates before today
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default DatesRangeInput;
