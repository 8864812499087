import React, { Fragment, useState } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonSubmit from "./ButtonSubmit";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import logoImg from "images/logo.png";
import { useNavigate } from "react-router-dom";
import { useSearchContext, SearchTab } from "context/search";
import { useTimeoutFn } from "react-use";

const HeroSearchForm2Mobile = () => {
  const navigate = useNavigate();
  const {
    location,
    dates,
    guests,
    setLocation,
    setDates,
    setGuests,
    searchPlaces,
    setSelectedType,
    selectedType,
  } = useSearchContext();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);

  function closeModal() {
    setShowModal(false);
    setErrorMessage(""); // Clear error message when closing the modal
  }

  function openModal() {
    setShowModal(true);
  }

  const handleSearch = async () => {
    if (!location || !dates.startDate || !guests) {
      setErrorMessage("Please fill all fields");
      return;
    }

    try {
      console.log("search function being called from stay component");
      await searchPlaces();

      // Construct query parameters to pass the state data
      const queryParams = new URLSearchParams({
        location,
        guests: guests.toString(),
        startDate: dates.startDate.toISOString(),
        selectedType, // Add selectedType to the query parameters
      });

      closeModal();
      // Navigate to the page with query parameters
      navigate(`search-results?${queryParams}`);
    } catch (error) {
      console.error("Error searching for places:", error);
      setErrorMessage("Error searching for places");
    }
  };

  const handleClearAll = () => {
    setLocation(""); // Reset location to default or empty string
    setDates({ startDate: null, endDate: null }); // Reset dates to default values
    setGuests(0); // Reset guests to default or null
    setShowDialog(false); // Close the dialog
    resetIsShowingDialog(); // Reset the dialog state
  };

  const renderButtonOpenModal = () => {
    return (
      <div className="flex items-center">
        <a href="/" className="">
          <img src={logoImg} alt="logo" width={160} height={28} className="mr-8 w-auto h-7" />
        </a>
        <button
          onClick={openModal}
          className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 ml-3 rounded-full shadow-lg"
        >
          <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

          <div className="ml-3 flex-1 text-left overflow-hidden">
            <span className="block font-medium text-sm"> Location?</span>
            <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400">
              <span className="line-clamp-1">
                Anywhere • Any date • Add guests
              </span>
            </div>
          </div>

          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
            <svg
              viewBox="0 0 16 16"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              className="block w-4 h-4"
              fill="currentColor"
            >
              <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
            </svg>
          </span>
        </button>
      </div>
    );
  };

  const handleTabClick = (tab: SearchTab) => {
    setSelectedType(tab);
  };

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

                      <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-4 sm:space-x-8">
                        {["Venues", "Decorators", "Caterers", "Photographers"].map(
                          (item, index) => (
                            <Tab key={index} as={Fragment}>
                              {({ selected }) => (
                                <div
                                  className="relative focus:outline-none focus-visible:ring-0 outline-none select-none"
                                  onClick={() =>
                                    handleTabClick(item as SearchTab)
                                  }
                                >
                                  <div
                                    className={`${
                                      selected
                                        ? "text-black dark:text-white"
                                        : ""
                                    }  `}
                                  >
                                    {item}
                                  </div>
                                  {selected && (
                                    <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                  )}
                                </div>
                              )}
                            </Tab>
                          )
                        )}
                      </Tab.List>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                      {errorMessage && (
                        <div className="px-4 py-2 text-red-600 bg-red-100 border border-red-400 rounded">
                          {errorMessage}
                        </div>
                      )}
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={handleClearAll}
                        >
                          Clear all
                        </button>
                        <ButtonSubmit onClick={handleSearch} />
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile
