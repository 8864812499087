import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from 'sonner';
// import "react-toastify/dist/ReactToastify.css";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const GuestsInput: React.FC<GuestsInputProps> = ({
  fieldClassName = "nc-hero-field-padding",
  className = "nc-flex-1",
  buttonSubmitHref = "/search-results",
  hasButtonSubmit = true,
}) => {
  const { guests, setGuests, location, dates, selectedType, searchPlaces } = useSearchContext(); // Make sure selectedType is included from the context
  const [inputValue, setInputValue] = useState(guests || "");
  const navigate = useNavigate();

  const handleSelectChange = (value: number) => {
    setInputValue(value.toString());
    setGuests(value);
  };

  const handleSubmit = async () => {
    if (!location || !dates.startDate || !dates.endDate || !guests) {
      toast.error("Please fill all fields");
      return;
    }

    try {
      console.log("search function being called from stay component");
      await searchPlaces();

      // Construct query parameters to pass the state data, including selectedType
      const queryParams = new URLSearchParams({
        location,
        guests: guests.toString(),
        startDate: dates.startDate.toISOString(),
        selectedType, // Add selectedType to the query parameters
      });

      // Navigate with query parameters to preserve state
      navigate(`${buttonSubmitHref}?${queryParams}`);
    } catch (error) {
      console.error("Error searching for places:", error);
      toast.error("Error searching for places");
    }
  };

  const totalGuests = inputValue;

  return (
    <>
      <Popover className={`flex relative ${className}`}>
        {({ open }) => (
          <>
            <div
              className={`flex-1 z-10 flex items-center focus:outline-none ${open ? "nc-hero-field-focused" : ""
                }`}
            >
              <Popover.Button
                className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold">
                    {totalGuests ? `${totalGuests} Guests` : "Guests"}
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    {totalGuests ? "Guests" : "Add Guests"}
                  </span>
                </div>
                {!!totalGuests && open && (
                  <ClearDataButton
                    onClick={() => {
                      setInputValue("");
                      setGuests(0);
                    }}
                  />
                )}
              </Popover.Button>
              {hasButtonSubmit && (
                <div className="pr-2 xl:pr-4 z-10">
                  <ButtonSubmit onClick={handleSubmit} />
                </div>
              )}
            </div>

            {open && (
              <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
            )}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl max-h-[40vh] overflow-y-auto">
                <div className="flex flex-col space-y-2">
                  {Array.from({ length: 20 }, (_, i) => 50 + i * 50).map((num) => (
                    <button
                      key={num}
                      type="button"
                      className={`p-2 border border-gray-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-lg text-center ${inputValue == num ? "bg-neutral-100 dark:bg-neutral-700" : "bg-white dark:bg-neutral-800"
                        }`}
                      onClick={() => handleSelectChange(num)}
                    >
                      {num} Guests
                    </button>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Toaster richColors />    
      </>
  );
};

export default GuestsInput;
