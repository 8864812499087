import React from "react";
import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handlePrivacyPolicyClick = () => {
    navigate("/privacy-policy");
  };

  const handleTermsClick = () => {
    navigate("/terms-and-conditions");
  };

  const handleRefundPolicyClick = () => {
    navigate("/refund-policy");
  };

  return (
    <div className="nc-Footer relative py-6 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container flex justify-between items-center">
        <Logo />
        <div className="flex flex-col items-end">
          <SocialsList1 className="flex items-center space-x-3 mb-2" />
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 text-right sm:text-left">
            <a
              href="/terms-and-conditions"
              className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
              onClick={handleTermsClick}
            >
              Terms and Conditions
            </a>
            <a
              href="/privacy-policy"
              className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
              onClick={handlePrivacyPolicyClick}
            >
              Privacy Policy
            </a>
            <a
              href="/refund-policy"
              className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
              onClick={handleRefundPolicyClick}
            >
              Refund Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
