// CardCategory3Skeleton.tsx
import React from "react";

const CardCategory3Skeleton: React.FC = () => {
  return (
    <div className="nc-CardCategory3 flex flex-col animate-pulse">
      <div className="flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden bg-gray-300"></div>
      <div className="mt-4">
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        <div className="mt-2 h-3 bg-gray-300 rounded w-1/2"></div>
      </div>
    </div>
  );
};

export default CardCategory3Skeleton;
