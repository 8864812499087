import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePageHalls from "components/SectionHeroArchivePage/SectionHeroArchivePageHalls";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useSearchContext } from "context/search";
import { BACKEND_URL } from "backendUrl";
import { GoogleCalendarEvent, Menu, VendorResponse } from "data/types";
import { useParams } from 'react-router-dom';
import { FaUtensils, FaDrumstickBite, FaWineGlassAlt, FaIceCream } from "react-icons/fa";
import Heading3 from "components/Heading/Heading3";
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";


export interface ListingStayPageProps {
  className?: string;
}


const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const { selectedVenue, setSelectedVenue, setSelectedMenu, selectedMenu } = useSearchContext();
  const [events, setEvents] = useState<GoogleCalendarEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedMenuId, setSelectedMenuId] = useState<string>("");
  const { vendorId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendorData = async () => {
      if (!vendorId) return;

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${BACKEND_URL}places/${vendorId}`);
        setSelectedVenue(response.data);

        // Ensure setSelectedHall is defined before calling
        if (response.data.type === "Halls" && typeof setSelectedHall === "function") {
          setSelectedHall(response.data.hall);
        }

        // Set selectedMenuId only if it is not already set and is a caterer
        if (!selectedMenuId && response.data.type === "Caterers") {
          const initialSelectedMenuId = selectedMenu?._id || response.data?.menus?.[0]?._id || "";
          setSelectedMenuId(initialSelectedMenuId);
        }

        setLoading(false);
      } catch (error) {
        console.error(
          'Error fetching vendor data:',
          // error.response ? error.response.data : error.message
        );
        setError('Failed to load vendor data. Please try again later.');
        setLoading(false);
      }
    };

    fetchVendorData();
  }, [vendorId]);





  // useEffect(() => {
  //   const fetchVendorData = async () => {
  //     if (!vendorId) return;

  //     setLoading(true);
  //     setError(null);

  //     try {
  //       const response = await axios.get(`${BACKEND_URL}places/${vendorId}`);
  //       setSelectedVenue(response.data);
  //       setSelectedMenuId(response.data?.menus?.[0]?._id || "");
  //       setLoading(false);
  //     } catch (error) {
  //       setError('Failed to load vendor data. Please try again later.');
  //       setLoading(false);
  //       console.error('Error fetching vendor data:', error);
  //     }
  //   };

  //   fetchVendorData();
  // }, [vendorId]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!selectedVenue?.vendor_email) {
        console.error('Vendor email not found in selectedVenue');
        return;
      }

      try {
        const response = await axios.get<VendorResponse>(`${BACKEND_URL}vendor-by-email`, {
          params: { email: selectedVenue.vendor_email }
        });

        const accessToken = response.data.accessToken;

        const eventsResponse = await axios.get<{ items: GoogleCalendarEvent[] }>(
          `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setEvents(eventsResponse.data.items);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    if (selectedVenue) {
      fetchEvents();
    }
  }, [selectedVenue]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <SyncLoader color={"#ffd02b"} loading={loading} size={15} margin={2} />
      </div>
    );
  }
  

  const renderMenuSection = () => {
    if (!selectedVenue?.menu_based || selectedVenue.menus.length === 0) return null;

    return (
      <div className="listingSectionSidebar__wrap hidden md:block mb-10">
        <h2 className="text-2xl font-semibold">Menu Pricing</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 " />

        <div className="flex flex-col md:flex-row">
          <div className="relative flex-shrink-0 w-full md:w-1/3 h-auto md:h-[490px] rounded overflow-x-auto md:overflow-y-auto pr-4 mb-4 md:mb-0">
            <div className="flex md:block space-x-4 md:space-x-0">
              {selectedVenue.menus.map((menu) => (
                <div
                  key={menu._id}
                  className={`p-4 mb-2 cursor-pointer flex items-center justify-between border rounded-md 
                    ${selectedMenuId === menu._id ? 'border-teal-700' : 'border-neutral-200 dark:border-neutral-700'} 
                    ${selectedMenuId === menu._id ? 'bg-teal-700 text-white' : 'bg-gray-50 dark:bg-neutral-800'}
                    hover:shadow-xl dark:hover:shadow-md transition-shadow w-3/4 md:w-full`}
                  onClick={() => {
                    setSelectedMenuId(menu._id);
                    setSelectedMenu(menu);
                  }}
                >
                  <div>
                    <h4 className={`text-lg font-semibold ${selectedMenuId === menu._id ? 'text-white dark:text-white' : 'text-gray-700 dark:text-white'}`}>
                      {menu.name}
                    </h4>
                    <p className={`font-medium text-sm ${selectedMenuId === menu._id ? 'text-white dark:text-neutral-300' : 'text-gray-500'}`}>
                      PKR {menu.price.toLocaleString()}
                    </p>
                  </div>
                  <span className={`text-3xl ${selectedMenuId === menu._id ? 'text-white' : 'text-gray-500'}`}>&#8250;</span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex-grow pl-0 md:pl-6">
            {selectedVenue.menus.map(
              (menu) =>
                menu._id === selectedMenuId && (
                  <div
                    key={menu._id}
                    className="p-6 bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-lg shadow-md flex flex-col md:flex-row md:items-start"
                  >
                    <div className="flex-grow">
                      <h4 className="text-xl font-semibold">{menu.name}</h4>
                      <p className="font-medium mb-4">Price: PKR {menu.price.toLocaleString()}<span className="text-xs text-neutral-600">/Per Person</span></p>
                      <div className="grid grid-cols-2 gap-4">
                        {menu.starters?.length > 1 && (
                          <div>
                            <div className="flex items-center mb-2">
                              <FaUtensils className="text-blue-500 mr-2" />
                              <h5 className="font-semibold">Starters</h5>
                            </div>
                            <ul className="ml-6 list-disc">
                              {menu.starters.map((item, index) => (
                                <li key={index} className="text-gray-700 dark:text-neutral-300">
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {menu.mainCourse?.length > 1 && (
                          <div>
                            <div className="flex items-center mb-2">
                              <FaDrumstickBite className="text-red-500 mr-2" />
                              <h5 className="font-semibold">Main Course</h5>
                            </div>
                            <ul className="ml-6 list-disc">
                              {menu.mainCourse.map((item, index) => (
                                <li key={index} className="text-gray-700 dark:text-neutral-300">
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {menu.drinks?.length > 1 && (
                          <div>
                            <div className="flex items-center mb-2">
                              <FaWineGlassAlt className="text-purple-500 mr-2" />
                              <h5 className="font-semibold">Drinks</h5>
                            </div>
                            <ul className="ml-6 list-disc">
                              {menu.drinks.map((item, index) => (
                                <li key={index} className="text-gray-700 dark:text-neutral-300">
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {menu.desserts?.length > 1 && (
                          <div>
                            <div className="flex items-center mb-2">
                              <FaIceCream className="text-yellow-500 mr-2" />
                              <h5 className="font-semibold">Desserts</h5>
                            </div>
                            <ul className="ml-6 list-disc">
                              {menu.desserts.map((item, index) => (
                                <li key={index} className="text-gray-700 dark:text-neutral-300">
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex-shrink-0 md:ml-6 mt-4 md:mt-0">
                      <button
                        className="px-4 py-2 bg-teal-600 text-white rounded-full hover:bg-teal-700 transition"
                        onClick={() => {
                          setSelectedMenu(menu);
                          setSelectedVenue(selectedVenue);

                          navigate(`/listing-vendor-detail/${selectedVenue?.id}/${selectedVenue?.type}/menu/${menu.name}/${menu._id}`);
                        }}
                      >
                        Select Menu
                      </button>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    );
  };



  const renderMenuSectionSm = () => {
    if (!selectedVenue?.menu_based || selectedVenue.menus.length === 0) return null;

    return (
      <div className="py-10 mb-10 min-h-[60vh] listingSection__wrap md:hidden">
        <h2 className="text-2xl font-semibold">Menu Pricing</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* Swipeable menu list for smaller screens */}
        <div className="overflow-x-auto flex space-x-4 pb-4 h-36">
          {selectedVenue.menus.map((menu: Menu) => (
            <div
              key={menu._id}
              className={`w-52 h-28 p-4 cursor-pointer flex-shrink-0 flex items-center justify-between border rounded-md relative
            ${selectedMenuId === menu._id ? 'border-teal-700' : 'border-neutral-200 dark:border-neutral-700'} 
            ${selectedMenuId === menu._id ? 'bg-teal-700 text-white' : 'bg-gray-50 dark:bg-neutral-800'}
            hover:shadow-xl dark:hover:shadow-md transition-all`}
              onClick={() => {
                setSelectedMenu(menu);
                setSelectedMenuId(menu._id)
              }}
            >
              <div>
                <h4 className={`text-md font-semibold ${selectedMenuId === menu._id ? 'text-white dark:text-white' : 'text-gray-700 dark:text-white'}`}>
                  {menu.name}
                </h4>
                <p className={`font-medium text-sm ${selectedMenuId === menu._id ? 'text-white dark:text-neutral-300' : 'text-gray-500'}`}>
                  PKR {menu.price.toLocaleString()}
                </p>
              </div>
              {/* Downward arrow appears only when the box is selected */}
              {selectedMenuId === menu._id && (
                <span
                  className="text-2xl absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-3/4 text-teal-700"
                >
                  &#9660; {/* Downward arrow */}
                </span>
              )}
            </div>
          ))}
        </div>

        {/* Details of the selected menu */}
        {selectedVenue.menus.map((menu: Menu) =>
          menu._id === selectedMenuId && (
            <div
              key={menu._id}
              className="mt-6 p-4 bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-lg shadow-md flex flex-col md:flex-row md:items-start"
            >
              <div className="flex-grow flex items-center">
                <div>
                  <h4 className="text-xl font-semibold">{menu.name}</h4>
                  <p className="font-medium mb-4">Price: PKR {menu.price.toLocaleString()}<span className="text-xs text-neutral-600">/Per Person</span></p>
                </div>
                <div className="ml-10 mb-4">
                  <button
                    className="px-4 py-2 text-sm bg-teal-600 text-white rounded hover:bg-teal-700 transition"
                    onClick={() => {
                      setSelectedMenu(menu);
                      setSelectedVenue(selectedVenue);

                      // Updated path to match the new route structure for menus
                      navigate(`/listing-vendor-detail/${selectedVenue?.id}/${selectedVenue?.type}/menu/${menu.name}/${menu._id}`);
                    }}
                  >
                    Select Menu
                  </button>
                </div>

              </div>
              <div className="grid grid-cols-1 gap-4 mt-4">
                {menu.starters && menu.starters.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaUtensils className="text-blue-500 mr-2" />
                      <h5 className="font-semibold">Starters</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.starters.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {menu.mainCourse && menu.mainCourse.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaDrumstickBite className="text-red-500 mr-2" />
                      <h5 className="font-semibold">Main Course</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.mainCourse.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {menu.drinks && menu.drinks.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaWineGlassAlt className="text-purple-500 mr-2" />
                      <h5 className="font-semibold">Drinks</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.drinks.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {menu.desserts && menu.desserts.length > 1 && (
                  <div>
                    <div className="flex items-center mb-2">
                      <FaIceCream className="text-yellow-500 mr-2" />
                      <h5 className="font-semibold">Desserts</h5>
                    </div>
                    <ul className="ml-6 list-disc">
                      {menu.desserts.map((item, index) => (
                        <li key={index} className="text-gray-700 dark:text-neutral-300">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

          )
        )}
      </div>
    );
  };



  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>{`tayari. | ${selectedVenue?.name || "Venue Details"}`}</title>
      </Helmet>

      <BgGlassmorphism />


      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePageHalls className="pt-10 pb-24 lg:pb-28 lg:pt-16" />

        {/* Show the menu section if the venue is a caterer */}
        {selectedVenue?.type === "Caterers" ? (
          <>
            <Heading3 />
            {renderMenuSection()}
            {renderMenuSectionSm()}
          </>
        ) : (
          <SectionGridFilterCard className="pb-24 lg:pb-28" events={events} />
        )}
      </div>
    </div>
  );
};

export default ListingStayPage;
function setSelectedHall(arg0: null) {
  throw new Error("Function not implemented.");
}

