import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { BACKEND_URL } from "backendUrl";
import { useFirebase } from "context/firebase";
import ClipLoader from "react-spinners/ClipLoader";

export interface PageAddListing5Props {}

interface Booking {
  _id: string;
  selected_hall?: {
    name: string;
  };
  selected_menu?: {
    name: string;
  };
  customer_name: string;
  customer_email: string;
  vendor_email: string;
  customer_contact_number: string;
  guests: number;
  selected_date: string;
  google_calendar_event_id: string;
}

const PageAddListing5: FC<PageAddListing5Props> = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { getAuth } = useFirebase();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [getAuth]);

  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}bookings`);
        const allBookings = response.data;

        const filteredBookings = await Promise.all(
          allBookings.map(async (booking: Booking) => {
            try {
              const vendorResponse = await axios.get(`${BACKEND_URL}vendor-by-email`, {
                params: { email: booking.vendor_email },
              });
              const accessToken = vendorResponse.data.accessToken;

              // Check if the event exists in Google Calendar
              const calendarResponse = await axios.get(
                `https://www.googleapis.com/calendar/v3/calendars/primary/events/${booking.google_calendar_event_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );

              if (calendarResponse.data) {
                return booking;
              }
            } catch (error) {
              console.warn(`Booking with ID ${booking._id} does not exist in Google Calendar.`);
            }
            return null;
          })
        );

        // Filter out null values (bookings that don't exist in Google Calendar)
        setBookings(filteredBookings.filter((booking) => booking !== null) as Booking[]);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const handleCancelBooking = async (bookingId: string, vendorEmail: string, eventId: string) => {
    try {
      // Fetch vendor access token
      const response = await axios.get(`${BACKEND_URL}vendor-by-email`, {
        params: { email: vendorEmail },
      });
      const accessToken = response.data.accessToken;

      // Delete the event from Google Calendar
      await axios.delete(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      await axios.delete(`${BACKEND_URL}bookings/${bookingId}`);

      console.log("Booking successfully cancelled.");
      alert("Booking successfully cancelled.");
      setBookings(bookings.filter((booking) => booking._id !== bookingId));
    } catch (error) {
      console.error("Error cancelling booking:", error);
      alert("There was an error cancelling the booking.");
    }
  };

  return (
    <CommonLayout index="05" backtHref="/" nextHref="/add-listing-6">
      <div>
        <h2 className="text-2xl font-semibold">All Bookings</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-2"></div>
        <div className="space-y-8">
          {loading ? (
            <div className="flex justify-center items-center">
              <ClipLoader color={"#123abc"} loading={loading} size={50} />
            </div>
          ) : (
            bookings.map((booking) => (
              <div key={booking._id} className="border p-4 rounded-md shadow-sm">
                {/* Display either the hall name or the menu name based on the booking type */}
                <h3 className="text-lg font-semibold">
                  Booking for {booking.selected_hall ? booking.selected_hall.name : booking.selected_menu?.name}
                </h3>
                <p>Customer Name: {booking.customer_name}</p>
                <p>Customer Email: {booking.customer_email}</p>
                <p>Vendor Email: {booking.vendor_email}</p>
                <p>Customer Contact: {booking.customer_contact_number}</p>
                <p>Guests: {booking.guests}</p>
                <p>Date: {new Date(booking.selected_date).toLocaleDateString()}</p>
                <ButtonPrimary
                  onClick={() =>
                    handleCancelBooking(booking._id, booking.vendor_email, booking.google_calendar_event_id)
                  }
                  className="mt-2 rounded-md flex justify-end"
                >
                  Cancel Booking
                </ButtonPrimary>
              </div>
            ))
          )}
        </div>
      </div>
    </CommonLayout>
  );
};

export default PageAddListing5;
