import React, { useState } from "react";
import { useSearchContext } from "context/search";
import { Toaster, toast } from 'sonner';
import "react-toastify/dist/ReactToastify.css";

interface CustomDatePickerProps {
  highlightDates: { date: Date, type: 'day' | 'night' | 'fullyBooked' }[];
  selectedDate: Date | null;
  onChange: (date: Date) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  highlightDates,
  selectedDate,
  onChange,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time for accurate comparison

  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const { setSelectedDate, setHallDayBooked, setHallNightBooked } = useSearchContext();

  const handlePreviousMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleDayClick = (date: Date, highlightInfo: { date: Date, type: 'day' | 'night' | 'fullyBooked' } | undefined) => {
    if (highlightInfo?.type === 'fullyBooked') {
      toast.error("This date is fully booked");
    } else {
      onChange(date);
      setSelectedDate(date);  // Update the selected date in the context

      // Update hallDayBooked or hallNightBooked based on the highlight type
      if (highlightInfo?.type === 'day') {
        setHallDayBooked(true);
        setHallNightBooked(false);
      } else if (highlightInfo?.type === 'night') {
        setHallDayBooked(false);
        setHallNightBooked(true);
      } else {
        setHallDayBooked(false);
        setHallNightBooked(false);
      }
    }
  };

  const renderDay = (day: number, date: Date) => {
    const highlightInfo = highlightDates.find(
      (highlightDate) =>
        date.getFullYear() === highlightDate.date.getFullYear() &&
        date.getMonth() === highlightDate.date.getMonth() &&
        date.getDate() === highlightDate.date.getDate()
    );

    let highlightClass = '';
    let textColorClass = '';
    let isDisabled = false;

    if (highlightInfo) {
      if (highlightInfo.type === 'day') {
        highlightClass = 'bg-yellow-500';
      } else if (highlightInfo.type === 'night') {
        highlightClass = 'bg-blue-900';
        textColorClass = 'text-white'; // Set text color to white for night bookings
      } else if (highlightInfo.type === 'fullyBooked') {
        highlightClass = 'bg-red-500';
      }
    }

    // Disable dates before today
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time for accurate comparison
    if (date < today) {
      isDisabled = true;
      textColorClass = 'text-gray-400'; // Grey out past dates
    }

    const isSelected =
      selectedDate &&
      date.getFullYear() === selectedDate.getFullYear() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getDate() === selectedDate.getDate();

    const isToday = date.getTime() === today.getTime();

    return (
      <div
        className={`p-2 cursor-pointer text-center rounded-full w-11 h-11 text-sm flex flex-col items-center justify-center ${highlightClass} ${textColorClass} ${isDisabled ? "cursor-not-allowed" : "hover:bg-gray-100 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"}`}
        onClick={() => !isDisabled && handleDayClick(date, highlightInfo)}
      >
        <span>{day}</span>
        {isSelected && (
          <span className="block w-2 h-2 mt-1 bg-teal-700 rounded-full"></span>
        )}
        {isToday && !isSelected && (
          <span className="block w-2 h-2 mt-1 bg-gray-500 rounded-full"></span>
        )}
      </div>
    );
  };

  const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const renderMonth = (month: number, year: number) => {
    const daysInMonth = getDaysInMonth(month, year);
    const firstDay = new Date(year, month, 1).getDay();

    const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

    return (
      <div className="bg-white rounded-lg dark:bg-neutral-900">
        <div className="grid grid-cols-7 gap-2 text-center font-normal text-xs mt-2">
          {daysOfWeek.map((day) => (
            <div key={day} className="text-gray-500">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-2 mt-5">
          {Array.from({ length: firstDay }, (_, i) => (
            <div key={`empty-${i}`}></div>
          ))}
          {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => {
            const date = new Date(year, month, day);
            return <div key={day}>{renderDay(day, date)}</div>;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white dark:bg-neutral-900">
      <Toaster richColors />
      <div className="flex justify-between items-center mb-4">
        <button
          className="text-xl font-semibold p-2 h-9 w-9 text-center flex items-center justify-center rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
          onClick={handlePreviousMonth}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
          </svg>
        </button>
        <div className="flex flex-col items-center space-y-2 sm:flex-row sm:space-x-8 sm:space-y-0">
          <span className="text-base font-semibold">
            Select Your Event Date
          </span>
        </div>
        <button
          className="text-xl font-semibold p-2 h-9 w-9 text-center flex items-center justify-center rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
          onClick={handleNextMonth}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-1 gap-12 sm:grid-cols-2">
        <div>
          <div className="text-center mb-4 text-base font-semibold">
            {new Date(currentYear, currentMonth).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </div>
          {renderMonth(currentMonth, currentYear)}
        </div>
        <div>
          <div className="text-center mb-4 text-base font-semibold">
            {new Date(
              currentYear,
              currentMonth === 11 ? 0 : currentMonth + 1
            ).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </div>
          {renderMonth(
            currentMonth === 11 ? 0 : currentMonth + 1,
            currentMonth === 11 ? currentYear + 1 : currentYear
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
