import Heading from "components/Heading/Heading";
import React from "react";

export interface People {
  id: string;
  name: string;
  job: string;
  description: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Ali Arshad`,
    job: "Founder & Chief Executive",
    description: "Ali Arshad is the visionary behind tayari., bringing years of experience in the event planning industry. His passion for creating seamless and enjoyable experiences led him to establish Tayari, aiming to simplify the event booking process for everyone. Ali's leadership and innovative approach drive the company towards continual growth and excellence.",
  },
  {
    id: "4",
    name: `Mariyah Khan`,
    job: "Co-founder",
    description: "Mariyah Khan, co-founder of tayari., enhances the platform's offerings with her unique perspective. Her innovative approach ensures that Tayari not only meets but exceeds the expectations of clients by providing beautifully curated and data-driven event solutions.",
  },
  {
    id: "3",
    name: `Naeem Ahmed`,
    job: "Development Manager",
    description: "Naeem Ahmed leads the development team at tayari., overseeing the technical aspects of the platform. With his extensive experience in software development and project management, Naeem ensures that Tayari remains user-friendly, reliable, and secure. His dedication to technological innovation helps keep the platform at the forefront of the industry.",
  },
  {
    id: "5",
    name: `Muhammad Saad`,
    job: "Social Media Manager",
    description: "Muhammad Saad is the creative force behind Tayari's social media presence. As the Social Media Manager, he is responsible for engaging with the community, sharing inspiring content, and promoting the brand across various platforms. Saad's strategic approach and creativity help build a strong online presence, attracting more users to the tayari. platform.",
  },
];

const renderDescription = (description: string) => {
  const parts = description.split(/(tayari\.)/g);
  return parts.map((part, index) =>
    part === 'tayari.'
      ? <span key={index} className="text-yellow-500">{part}</span>
      : part
  );
};

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="We’re impartial and independent, and every day we create distinctive,
          world-class programmes and content"
      >
        ⛱ Our Team
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
            <p className="text-sm text-neutral-700 dark:text-neutral-300 mt-2">
              {renderDescription(item.description)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
